import React from "react";
import { render } from "react-dom";
// Import Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);

export default function StackedGroupedBarChart(props) {
  const {
    themeHC,
    chartHeight,
    chartData,
    titleText,
    xAxisCategories,
    plotOptionsColumnStacking,
    plotOptionsColumnDataLabelsEndabled,
    chartRef3,
    seriesName,
    yAxisMax,
  } = props;

  const options = {
    chart: {
      type: "column",
    },

    title: {
      text: "Total fruit consumption, grouped by gender",
    },

    xAxis: {
      categories: ["Month 1", "Month 4"],
    },

    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: "Number of fruits",
      },
    },

    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          this.x +
          "</b><br/>" +
          this.series.name +
          ": " +
          this.y +
          "<br/>" +
          "Total: " +
          this.point.stackTotal
        );
      },
    },

    plotOptions: {
      column: {
        stacking: "normal",
      },
    },

    // series: [
    //   {
    //     name: "John",
    //     data: [5, 3, 4, 7, 2],
    //     stack: "male",
    //   },
    //   {
    //     name: "Joe",
    //     data: [3, 4, 4, 2, 5],
    //     stack: "male",
    //   },
    //   {
    //     name: "Jane",
    //     data: [2, 5, 6, 2, 1],
    //     stack: "female",
    //   },
    //   {
    //     name: "Janet",
    //     data: [3, 0, 4, 4, 3],
    //     stack: "female",
    //   },
    // ],
    series: chartData,
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        updateArgs={[true]}
        ref={chartRef3}
      />
    </div>
  );
}
