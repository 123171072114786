import React from "react";
import { render } from "react-dom";
// Import Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);

export default function StackedGrouped3dBarChart(props) {
  const {
    themeHC,
    chartHeight,
    chartData,
    titleText,
    xAxisCategories,
    xAxisfontSize,
    yAxisTitleText,
    yAxisMin,
    enabledDataLabels,
    caption,
  } = props;

  const options = {
    chart: {
      height: chartHeight,
      renderTo: "container",
      type: "column",
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 0,
        depth: 75,
        viewDistance: 100,
      },
      zoomType: "xy",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: titleText,
    },

    xAxis: {
      categories: xAxisCategories,
      labels: {
        skew3d: true,
        style: {
          fontSize: xAxisfontSize,
        },
      },
    },

    yAxis: {
      allowDecimals: true,
      min: yAxisMin,
      title: {
        text: yAxisTitleText,
        skew3d: false,
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        depth: 20,
        dataLabels: {
          enabled: enabledDataLabels,
        },
      },
    },

    series: chartData,
    caption: { text: caption },
    legend: {
      enabled: true,
      layout: "horizontal",
      // align: "right",
      verticalAlign: "bottom",
      itemMarginTop: 2,
      itemMarginBottom: 2,
      symbolWidth: 40,
      itemStyle: {
        fontSize: "10px",
      },
    },
    exporting: {
      sourceWidth: 1000,
      sourceHeight: 400,
      scale: 1,
    },
  };

  return (
    <div style={{ marginRight: "5px", marginTop: "5px" }}>
      <HighchartsReact
        // highcharts={themeHC(Highcharts)}
        highcharts={Highcharts}
        options={options}
        updateArgs={[true]}
      />
    </div>
  );
}
