import React, {
  useState,
  // useEffect,
  // useRef,
  useContext,
  useEffect,
  // useMemo,
  //    useCallback
} from "react";
// import axios from "axios";
// import { RhinoQueryContext } from "./RhinoQueryContext";
import DeckGL from "@deck.gl/react";
// import { FlyToInterpolator } from "@deck.gl/core";
import {
  ScatterplotLayer,
  // LineLayer,
  GeoJsonLayer,
} from "@deck.gl/layers";
import {
  GridLayer,
  // ScreenGridLayer,
  // ContourLayer,
} from "@deck.gl/aggregation-layers";
import {
  StaticMap,
  MapContext,
  NavigationControl,
  // Popup,
  ScaleControl,
  WebMercatorViewport,
} from "react-map-gl";

// import * as d3 from "d3";
import { makeStyles, Dialog } from "@material-ui/core";

// import CircularProgress from "@mui/material/CircularProgress";
// import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import LinearProgress from "@mui/material/LinearProgress";

// import { PathLayer } from "@deck.gl/layers";

// import bbox from "@turf/bbox";

// import { sampleStateData } from "../TestData/sampleStateData";

// import axios from "axios";

// import { QueryContext } from "../context/QueryContext";

// import DataParameters from "./DataParameters";

// import LayerControls from "./contols/LayerControls";

// import { hexToRgb } from "../utils/utils";

import countyGeom from "../TestData/countyGeom.json";
import districtGeom from "../TestData/districtGeom.json";
import stateGeom from "../TestData/stateGeom.json";

import bbox from "@turf/bbox";

const useStyles = makeStyles({
  deckglMap: {
    position: "relative",
  },
  dialog: {
    position: "absolute",
    left: "92%",
    top: "50%",
    transform: "translate(-75%,-50%)",
  },
  dialog2: {
    position: "absolute",
    left: "69vw",
    top: "27vh",
  },
  dataParameters: {
    position: "absolute",
    left: "94%",
    top: "35%",
    transform: "translate(-75%,-50%)",
    color: "black",
  },
  boxBackground: {
    backgroundColor: "white",
    color: "blue",
    fontSize: 12,
  },
});

function DeckglMap(props) {
  const classes = useStyles();
  const {
    statusGuide,
    // regionSelected,
    // setRegionSelected,
    // statewideData,
    // isLoading,
    // landUseData,
    deckRef,
  } = props;
  // console.log("statewideData-check", statewideData);
  //   const [query, dispatch] = useContext(QueryContext);

  const [viewport, setViewport] = useState({
    // height: "500px",
    //height: "65vh",
    // height: "65%",
    // width: "100%",
    //initViewport
    width: "100%",
    height: "100%",
  });

  const NAV_CONTROL_STYLE = {
    position: "absolute",
    top: 5,
    left: 5,
  };
  const ScaleControl_STYLE = {
    position: "absolute",
    top: 350,
    left: 5,
  };
  const layers_CONTROL_STYLE = {
    position: "absolute",
    top: 90,
    left: 5,
  };

  const [homeMapBounds, setHomeMapBounds] = useState({
    // longitude: -99.458333,
    // latitude: 31.243333,
    longitude: -99.458333,
    latitude: 31.243333,
    zoom: 4.85,
  });

  const [baseMap, setBaseMap] = useState("mapbox://styles/mapbox/streets-v11");

  const [boundaryData, setBoundaryData] = useState({
    type: "FeatureCollection",
    features: stateGeom,
  });
  //   useEffect(() => {
  //     if (statusGuide) {
  //       if (statusGuide.category === "state")
  //         return setBoundaryData({
  //           type: "FeatureCollection",
  //           features: stateGeom,
  //         });

  //       if (statusGuide.category === "county")
  //         return setBoundaryData({
  //           type: "FeatureCollection",
  //           features: countyGeom.filter(
  //             (item) =>
  //               item.properties.cnty_nm.toUpperCase() ===
  //               statusGuide.reportingArea
  //           ),
  //         });
  //     }
  //   }, [statusGuide]);
  useEffect(() => {
    let boundaryDataTemp = {};
    if (statusGuide) {
      if (statusGuide.category === "state")
        boundaryDataTemp = {
          type: "FeatureCollection",
          features: countyGeom,
        };

      if (statusGuide.category === "county")
        boundaryDataTemp = {
          type: "FeatureCollection",
          features: countyGeom.filter(
            (item) =>
              item.properties.cnty_nm.toUpperCase() ===
              statusGuide.reportingArea
          ),
        };

      if (
        statusGuide.category === "region" ||
        statusGuide.category === "district"
      )
        boundaryDataTemp = {
          type: "FeatureCollection",
          features: countyGeom.filter((item) =>
            statusGuide.counties.includes(item.properties.cnty_nbr)
          ),
        };

      setBoundaryData(boundaryDataTemp);

      const mapBounds_bbox = bbox(boundaryDataTemp);
      setHomeMapBounds(getBoundsForRoads(mapBounds_bbox));
    }
  }, [statusGuide]);
  // console.log("boundaryData-check", boundaryData, statusGuide);

  function getBoundsForRoads(mapBounds_bbox) {
    // Calculate corner values of bounds
    // const pointsLong = points.map((point) => point.coordinates._long);
    // const pointsLat = points.map((point) => point.coordinates._lat);
    // const cornersLongLat = [
    //   [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
    //   [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
    // ];
    const cornersLongLat = [
      [mapBounds_bbox[0], mapBounds_bbox[1]],
      [mapBounds_bbox[2], mapBounds_bbox[3]],
    ];
    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({
      width: 800,
      height: 600,
    }).fitBounds(cornersLongLat, { padding: 100 }); // Can also use option: offset: [0, -100]
    // const viewport = new WebMercatorViewport({
    //   width: 800,
    //   height: 600,
    // }).fitBounds(mapBounds_bbox, { padding: 200 }); // Can also use option: offset: [0, -100]
    const { longitude, latitude, zoom } = viewport;
    return { longitude, latitude, zoom };
  }

  //   function deckGlTooltip(layer, object) {
  //     // console.log("object-check", object);
  //     let toolTipStyle = {
  //       backgroundColor: "#e1e7ed",
  //       color: "#000000",
  //       fontSize: "1.0em",
  //     };
  //     if (layer.id === "statewideData-grid") {
  //       let vehWeightClassLabel = vehicleWeightClassOptions.filter(
  //         (obj) => obj.value === parseInt(query.selectedVehicleWeightClass)
  //       )[0].label;
  //       return {
  //         html:
  //           `<h7><strong>Cluster of Parking Events</strong></h7>` +
  //           `<br />` +
  //           `<h7>Count: </h7> ${object.count.toLocaleString()}` +
  //           `<br />` +
  //           regionSelected.selected +
  //           `<br />` +
  //           regionSelected.year +
  //           `-` +
  //           regionSelected.month +
  //           `<br />` +
  //           regionParkingDataTypes[regionSelected.regionParkingDataType].label +
  //           `<br />` +
  //           vehWeightClassLabel +
  //           `<br />` +
  //           query.selectedDayType,
  //         style: toolTipStyle,
  //       };
  //     }
  //     // "landUse"

  //     if (layer.id === "statewideData") {
  //       let vehWeightClassLabel = vehicleWeightClassOptions.filter(
  //         (obj) => obj.value === object.vehicleweightclass
  //       )[0].label;
  //       return {
  //         html:
  //           `<h7><strong>Parking Event</strong></h7>` +
  //           `<br />` +
  //           `<h7>Parking Duration: </h7> ${object.minutes.toLocaleString()} minutes` +
  //           `<br />` +
  //           `<h7>Parking Duration Category: </h7> ${
  //             regionParkingDataTypes[regionSelected.regionParkingDataType].label
  //           } minutes` +
  //           `<br />` +
  //           regionSelected.year +
  //           `-` +
  //           regionSelected.month +
  //           `<br />` +
  //           vehWeightClassLabel +
  //           `<br />` +
  //           `${object.dow_desc}`,
  //         style: toolTipStyle,
  //       };
  //     }
  //     if (layer.id === "landUse")
  //       return {
  //         html: `<h7>Land Use Type: </h7> ${object.properties.Description}`,
  //         style: toolTipStyle,
  //       };

  //     return;
  //   }

  // console.log("isLoading-check", isLoading);
  // console.log("landUseData-check", landUseData);

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <DeckGL
        ref={deckRef}
        {...viewport}
        // initialViewState={initialViewState}
        initialViewState={{
          longitude: homeMapBounds.longitude,
          latitude: homeMapBounds.latitude,
          zoom: homeMapBounds.zoom,
          pitch: 0,
          bearing: 0,
          //   transitionDuration: 300,
          // transitionInterpolator: new FlyToInterpolator(),
        }}
        // getTooltip={
        //   ({ layer, object }) =>
        //     // console.log("object-check", layer)
        //     object && deckGlTooltip(layer, object)
        //   //   html: `<h5>${object.properties.road}</h5><div>From: ${object.properties.from_road}</div>
        //   // <div>To: ${object.properties.to_road}</div>
        //   // <div>arank: ${object.properties.arank}</div>
        //   // <div>trank: ${object.properties.trank}</div>`,
        //   //   style: {
        //   //     backgroundColor: "#e1e7ed",
        //   //     color: "000000",
        //   //     fontSize: "1.0em",
        //   //   },
        //   // }
        // }
        controller={true}
        ContextProvider={MapContext.Provider}
        // onViewStateChange={({ viewState }) => {
        //   // console.log("viewState-change", viewState);
        //   setZoomLevel(viewState.zoom);
        //   // buildGeoJsonLayer();
        // }}
      >
        <StaticMap
          //   reuseMaps
          // mapStyle={"mapbox://styles/mapbox/streets-v11"}
          mapStyle={baseMap}
          // mapboxApiAccessToken={MAPBOX_ACCESSTOKEN}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESSTOKEN}
          preventStyleDiffing={true}
          preserveDrawingBuffer={true}
        />

        {/* {landUseData !== null && showLandUse && ( */}
        {/* {boundaryData && ( */}
        <GeoJsonLayer
          id="boundary"
          data={boundaryData}
          pickable={true}
          opacity={0.8}
          stroked={true}
          filled={true}
          radiusMaxPixels={10}
          getRadius={6000}
          radiusScale={2}
          // getTooltip={
          //   ({ layer, object }) =>
          //     // console.log("object-check", layer)
          //     object && deckGlTooltip(layer, object)
          //   //   html: `<h5>${object.properties.road}</h5><div>From: ${object.properties.from_road}</div>
          //   // <div>To: ${object.properties.to_road}</div>
          //   // <div>arank: ${object.properties.arank}</div>
          //   // <div>trank: ${object.properties.trank}</div>`,
          //   //   style: {
          //   //     backgroundColor: "#e1e7ed",
          //   //     color: "000000",
          //   //     fontSize: "1.0em",
          //   //   },
          //   // }
          // }
          autoHighlight={true}
          highlightColor={[160, 160, 180, 100]}
          getLineColor={[46, 49, 49]}
          getFillColor={(d) => {
            //   if (d.properties.Description === "Very Low Density Residential")
            //     return [247, 220, 180, 150];
            //   if (d.properties.Description === "Low Density Residential")
            //     return [255, 204, 0, 150];
            //   if (d.properties.Description === "Medium Density Residential")
            //     return [255, 153, 0, 150];
            //   if (d.properties.Description === "High Density Residential")
            //     return [255, 102, 0, 150];
            //   if (d.properties.Description === "Commercial")
            //     return [255, 0, 0, 150];
            //   if (d.properties.Description === "Industrial")
            //     return [106, 13, 173, 150];
            //   if (d.properties.Description === "Institutional")
            //     return [51, 102, 153, 150];
            //   if (d.properties.Description === "Other Developed Lands")
            //     return [126, 126, 126, 150];
            //   if (d.properties.Description === "Agriculture")
            //     return [0, 255, 0, 150];
            //   if (d.properties.Description === "Transportation")
            //     return [147, 0, 255, 150];
            return [0, 255, 0, 150];
          }}
          // updateTriggers={{
          //   getRadius: [viewport.zoom],
          // }}
          // transitions={{
          //   zoomLevel: 500,
          // }}
        />
        {/* )} */}

        {/* <NavigationControl style={NAV_CONTROL_STYLE} captureScroll={true} /> */}
        {/* <ScaleControl style={ScaleControl_STYLE} /> */}
        {/* <div className={classes.dataParameters}>
          <DataParameters
            regionSelected={regionSelected}
            setRegionSelected={setRegionSelected}
          />
        </div> */}
        {/* <div style={layers_CONTROL_STYLE}>
          <LayerControls
            baseMap={baseMap}
            setBaseMap={setBaseMap}
            // showRegionParking={showRegionParking}
            // setShowRegionParking={setShowRegionParking}
            // showLandUse={showLandUse}
            // setShowLandUse={setShowLandUse}
            dataLayers={dataLayers}
          />
        </div> */}
      </DeckGL>
    </div>
  );
}

export default DeckglMap;
