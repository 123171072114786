import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import Papa from "papaparse";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@material-ui/core/Typography";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Input from "@mui/material/Input";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function Downloads(props) {
  const { deckRef, printRef, mobilityData } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setFileName(mobilityData[0].Reporting_Area);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [downloadType, setDownloadType] = React.useState("NONE");
  React.useEffect(() => {
    if (downloadType === "PDF") {
      const handleDownloadPdf = async () => {
        deckRef.current.deck.redraw(true);
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL("image/png");

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth() * 0.95;
        const pdfHeight =
          (imgProperties.height * pdfWidth) / imgProperties.width;
        // const pdf = new jsPDF("l");
        // const imgProperties = pdf.getImageProperties(data);
        // const pdfHeight = pdf.internal.pageSize.getHeight();
        // const pdfWidth =
        //   (imgProperties.width * pdfHeight) / imgProperties.height;

        pdf.addImage(data, "PNG", 5, 10, pdfWidth, pdfHeight);
        pdf.save(fileName + "pdf");
      };

      handleDownloadPdf();
    }
    if (downloadType === "PNG") {
      const handleDownloadPng = async () => {
        deckRef.current.deck.redraw(true);
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL("image/png");
        saveAs(data, fileName + ".png");
      };
      const saveAs = (blob, fileName) => {
        var elem = window.document.createElement("a");
        elem.href = blob;
        elem.download = fileName;
        elem.style = "display:none;";
        (document.body || document.documentElement).appendChild(elem);
        if (typeof elem.click === "function") {
          elem.click();
        } else {
          elem.target = "_blank";
          elem.dispatchEvent(
            new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: true,
            })
          );
        }
        URL.revokeObjectURL(elem.href);
        elem.remove();
      };
      handleDownloadPng();
    }
    if (downloadType === "CSV") {
      const handleDownloadCsv = async () => {
        let fields = [
          "Reporting_Area",
          "YYQQ",
          "TTI_PEAKS_Mean",
          "PTI95_PEAKS_Mean",
          "PTI80_PEAKS_Mean",
          "AvgSpeed_PEAKS_Mean",
          "TTI_AM_Mean",
          "PTI95_AM_Mean",
          "PTI80_AM_Mean",
          "AvgSpeed_AM_Mean",
          "TTI_PM_Mean",
          "PTI95_PM_Mean",
          "PTI80_PM_Mean",
          "AvgSpeed_PM_Mean",
          "TTI_MD_Mean",
          "PTI95_MD_Mean",
          "PTI80_MD_Mean",
          "AvgSpeed_MD_Mean",
          "TTI_ON_Mean",
          "PTI95_ON_Mean",
          "PTI80_ON_Mean",
          "AvgSpeed_ON_Mean",
          "TTI_WE_Mean",
          "PTI95_WE_Mean",
          "PTI80_WE_Mean",
          "AvgSpeed_WE_Mean",
          "TTI_ALL247_Mean",
          "PTI95_ALL247_Mean",
          "PTI80_ALL247_Mean",
          "AvgSpeed_ALL247_Mean",
        ];

        const data = mobilityData.map((obj) => [
          obj.Reporting_Area,
          obj.YYQQ,
          obj.TTI_PEAKS_Mean,
          obj.PTI95_PEAKS_Mean,
          obj.PTI80_PEAKS_Mean,
          obj.AvgSpeed_PEAKS_Mean,
          obj.TTI_AM_Mean,
          obj.PTI95_AM_Mean,
          obj.PTI80_AM_Mean,
          obj.AvgSpeed_AM_Mean,
          obj.TTI_PM_Mean,
          obj.PTI95_PM_Mean,
          obj.PTI80_PM_Mean,
          obj.AvgSpeed_PM_Mean,
          obj.TTI_MD_Mean,
          obj.PTI95_MD_Mean,
          obj.PTI80_MD_Mean,
          obj.AvgSpeed_MD_Mean,
          obj.TTI_ON_Mean,
          obj.PTI95_ON_Mean,
          obj.PTI80_ON_Mean,
          obj.AvgSpeed_ON_Mean,
          obj.TTI_WE_Mean,
          obj.PTI95_WE_Mean,
          obj.PTI80_WE_Mean,
          obj.AvgSpeed_WE_Mean,
          obj.TTI_ALL247_Mean,
          obj.PTI95_ALL247_Mean,
          obj.PTI80_ALL247_Mean,
          obj.AvgSpeed_ALL247_Mean,
        ]);
        const csv = Papa.unparse({
          data,
          fields,
        });
        const blob = new Blob([csv]);
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob, { type: "text/plain" });
        a.download = fileName + ".csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };

      handleDownloadCsv();
    }
    setDownloadType("NONE");
  }, [downloadType]);

  const handleDownloadPdfClick = () => {
    setDownloadType("PDF");
  };
  const handleDownloadPngClick = () => {
    setDownloadType("PNG");
  };
  const handleDownloadCsvClick = () => {
    setDownloadType("CSV");
  };
  const [fileName, setFileName] = React.useState("File Name");
  const handleFilenameChange = (event) => {
    setFileName(event.target.value);
    event.preventDefault();
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open draggable dialog
      </Button> */}
      <IconButton
        size="medium"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        // onClick={(event) => handleMenu("region", event)}
        onClick={handleClickOpen}
        color="inherit"
        id="region"
      >
        <Box sx={{ m: 0.5 }}>
          <Typography variant="h6" noWrap>
            Downloads
          </Typography>
        </Box>
        <FileDownloadIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Downloads
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the filename without the extension (i.e., csv, pdf) in
            the field below. Then select the download format using the buttons
            at the bottom of this dialog.
          </DialogContentText>
          <Box sx={{ m: 0.5, mt: 2 }}>
            <Typography variant="h6" noWrap>
              Filename:
              <Input
                defaultValue="Hello world"
                value={fileName}
                // inputProps={ariaLabel}
                onChange={handleFilenameChange}
                sx={{ ml: 2 }}
              />
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={setDownloadType("PDF")}>PDF</Button>
          <Button onClick={setDownloadType("PNG")}>Image (PNG)</Button> */}
          <Button onClick={handleDownloadPdfClick}>PDF</Button>
          <Button onClick={handleDownloadPngClick}>Image (PNG)</Button>
          <Button onClick={handleDownloadCsvClick}>Data CSV</Button>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
