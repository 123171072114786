import React, { useState, useContext, useEffect } from "react";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
import MaterialTable from "material-table";

import Box from "@mui/material/Box";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { reportingYearsQuarters } from "../common/constants";

// import { QueryContext } from "../context/QueryContext";

// const useStyles = makeStyles((theme) => ({
//     root: {
//       backgroundColor: theme.palette.background.paper,
//       width: "50vw",
//       height: "25vh"
//     },
//   }));

export default function TablePanel(props) {
  // const classes = useStyles();
  //   const [query, dispatch] = useContext(QueryContext);
  //TTI_ALL247_Mean,PTI95_ALL247_Mean,PTI80_ALL247_Mean,AvgSpeed_ALL247_Mean
  const summaryTableParameters = [
    { field: "TTI_ALL247_Mean", summaryDescription: "TTI - Daily" },
    {
      field: "PTI95_ALL247_Mean",
      summaryDescription: "PTI 95 - Daily",
    },
    {
      field: "PTI80_ALL247_Mean",
      summaryDescription: "PTI 80 - Daily",
    },
    {
      field: "AvgSpeed_ALL247_Mean",
      summaryDescription: "Average Speed - Daily",
    },
    { field: "TTI_PEAKS_Mean", summaryDescription: "TTI - Peaks" },
    {
      field: "PTI95_PEAKS_Mean",
      summaryDescription: "PTI 95 - Peaks",
    },
    {
      field: "PTI80_PEAKS_Mean",
      summaryDescription: "PTI 80 - Peaks",
    },
    {
      field: "AvgSpeed_PEAKS_Mean",
      summaryDescription: "Average Speed - Peaks",
    },
    { field: "TTI_WE_Mean", summaryDescription: "TTI - Weekend" },
    {
      field: "PTI95_WE_Mean",
      summaryDescription: "PTI 95 - Weekend",
    },
    {
      field: "PTI80_WE_Mean",
      summaryDescription: "PTI 80 - Weekend",
    },
    {
      field: "AvgSpeed_WE_Mean",
      summaryDescription: "Average Speed - Weekend",
    },
  ];
  const { statusGuide, mobilityData } = props;
  // console.log("statusGuide-check", statusGuide);

  //   const [tableData, setTableData] = useState(query.regionCategoryTableData);
  const [summaryTableData, setSummaryTableData] = useState([]);
  useEffect(() => {
    if (mobilityData) {
      let summaryTableDataTemp = [];
      // summaryTableDataTemp.push({
      //   summaryDescription: "Test 1",
      //   currentQuarter: 1.6,
      //   priorQuarter: 1.1,
      // });

      let currentYearQuarter =
        reportingYearsQuarters[reportingYearsQuarters.length - 1].abbrev;
      let previousYearQuarter =
        reportingYearsQuarters[reportingYearsQuarters.length - 2].abbrev;

      summaryTableParameters.map((obj) => {
        let current = mobilityData.filter(
          (item) => item.YYQQ === currentYearQuarter
        )[0][obj.field];
        let prior = mobilityData.filter(
          (item) => item.YYQQ === previousYearQuarter
        )[0][obj.field];
        let change = current - prior;

        summaryTableDataTemp.push({
          summaryDescription: obj.summaryDescription,
          currentQuarter: (current * 1).toFixed(2),
          priorQuarter: (prior * 1).toFixed(2),
          change: change.toFixed(2),
        });
      });
      //Test stuff
      // setSummaryTableData([
      //   { summaryDescription: "Test 1", currentQuarter: 1.4, priorQuarter: 1.3 },
      // ]);
      setSummaryTableData(summaryTableDataTemp);
    }
  }, [mobilityData]);
  // console.log("summaryTableData-check", summaryTableData);
  return (
    <div style={{ paddingRight: "7px" }}>
      <Box
        component="span"
        sx={{
          // display: "flex",
          alignItems: "center",
          // flexWrap: "wrap",
          justify: "center",
          mb: 0,
          pb: 0,
        }}
      >
        {summaryTableData && (
          <MaterialTable
            columns={[
              { title: "Parameter", field: "summaryDescription" },
              {
                // title: "2021 Q4",
                title:
                  reportingYearsQuarters[reportingYearsQuarters.length - 1]
                    .year +
                  " " +
                  reportingYearsQuarters[reportingYearsQuarters.length - 1]
                    .quarter,
                field: "currentQuarter",
                type: "numeric",
              },
              {
                // title: "2021 Q3",
                title:
                  reportingYearsQuarters[reportingYearsQuarters.length - 2]
                    .year +
                  " " +
                  reportingYearsQuarters[reportingYearsQuarters.length - 2]
                    .quarter,
                field: "priorQuarter",
                type: "numeric",
              },
              {
                title: "Change",
                field: "change",
                type: "numeric",
                // cellStyle: (rowData) => ({
                //   // backgroundColor: rowData > 0 ? "red" : "#2e7d32",
                //   color: rowData > 0 ? "red" : "#2e7d32",
                // }),
                cellStyle: (rowData, index) => {
                  // backgroundColor: rowData > 0 ? "red" : "#2e7d32",
                  // console.log(
                  //   "rowData-check",
                  //   rowData,
                  //   index.summaryDescription
                  // );
                  return {
                    color:
                      index.summaryDescription == "Average Speed - Daily" ||
                      index.summaryDescription == "Average Speed - Peaks" ||
                      index.summaryDescription == "Average Speed - Weekend"
                        ? rowData < 0
                          ? "red"
                          : "#2e7d32"
                        : rowData > 0
                        ? "red"
                        : "#2e7d32",
                  };
                },
                // render: (rowData) => {
                //   return rowData.summaryDescription ==
                //     "Average Speed - Daily" ||
                //     rowData.summaryDescription == "Average Speed - Peaks" ||
                //     rowData.summaryDescription == "Average Speed - Weekend" ? (
                //     rowData.change >= 0 ? (
                //       <p style={{ color: "#2e7d32" }}>{rowData.change}</p>
                //     ) : (
                //       <p style={{ color: "red" }}>{rowData.change}</p>
                //     )
                //   ) : rowData.change > 0 ? (
                //     <p style={{ color: "red" }}>{rowData.change}</p>
                //   ) : (
                //     <p style={{ color: "#2e7d32" }}>{rowData.change}</p>
                //   );
                // },
              },

              // {
              //   title: "Year",
              //   field: "start_year",
              //   width: "5%",
              //   hidden: true,
              //   export: true,
              // },
              // { title: "Month", field: "start_month" },
              // {
              //   title: "Events < 1 hour",
              //   field: "parking_cat_1",
              //   type: "numeric",
              //   hidden: true,
              //   export: true,
              // },
              // {
              //   title: "Events >= 1, < 3 hours",
              //   field: "parking_cat_2",
              //   type: "numeric",
              //   hidden: true,
              //   export: true,
              // },
              // {
              //   title: "Events >= 3, < 7 hours",
              //   field: "parking_cat_3",
              //   type: "numeric",
              //   hidden: true,
              //   export: true,
              // },
              // {
              //   title: "Events >= 7, < 11 hours",
              //   field: "parking_cat_4",
              //   type: "numeric",
              //   hidden: true,
              //   export: true,
              // },
              // {
              //   title: "Events >= 11 hours",
              //   field: "parking_cat_5",
              //   type: "numeric",
              //   hidden: true,
              //   export: true,
              // },
              // {
              //   title: "Total Events",
              //   field: "parking_cat_total",
              //   type: "numeric",
              //   hidden: true,
              //   export: true,
              // },
              // {
              //   title: "% < 1 hour",
              //   field: "parking_cat_1_pc",
              //   type: "numeric",
              // },
              // {
              //   title: "% >= 1, < 3 hours",
              //   field: "parking_cat_2_pc",
              //   type: "numeric",
              // },
              // {
              //   title: "% >= 3, < 7 hours",
              //   field: "parking_cat_3_pc",
              //   type: "numeric",
              // },
              // {
              //   title: "% >= 7, < 11 hours",
              //   field: "parking_cat_4_pc",
              //   type: "numeric",
              // },
              // {
              //   title: "% >= 11 hours",
              //   field: "parking_cat_5_pc",
              //   type: "numeric",
              // },
              // {
              //   title: "Total",
              //   field: "parking_cat_total_pc",
              //   type: "numeric",
              // },
            ]}
            data={summaryTableData}
            title={statusGuide.label + " Quarterly Truck Mobility Report"}
            //   title={"TEST TITLE"}

            options={{
              exportButton: { csv: true, pdf: false },
              // maxBodyHeight: "25vh"
              exportAllData: true,
              // exportFileName:
              //   tableData[0].region +
              //   "_" +
              //   tableData[0].start_year +
              //   "-" +
              //   tableData[0].start_month +
              //   "-" +
              //   (query.selectedDayType === "All Daytypes"
              //     ? "AllDaytypes"
              //     : query.selectedDayType) +
              //   "_" +
              //   (query.selectedVehicleWeightClass === 0
              //     ? "alltrucks"
              //     : "vehwghtclass" + query.selectedVehicleWeightClass) +
              //   "_truckparkingevents",
              paging: false,
              rowStyle: {
                wordWrap: "break-word",
              },
              padding: "dense",
              // tableLayout: "auto",
              //   doubleHorizontalScroll: true,
              // detailPanelType: "multiple",
              search: false,
            }}

            //   detailPanel={[
            //     {
            //       // disabled: roadLayerType === "MD100",
            //       tooltip: "Show Detailed Information",
            //       render: (rowData) => {
            //         // console.log("rowData-check", rowData[0]);
            //         return (
            //           <div
            //             style={{
            //               fontSize: 100,
            //               textAlign: "center",
            //               color: "white",
            //               backgroundColor: "#43A047",
            //             }}
            //           >
            //             {materialTableDetailPanel(rowData)}
            //           </div>
            //         );
            //       },
            //     },
            //   ]}
          />
        )}
      </Box>
      <Card sx={{ mt: 0, pt: 0 }}>
        <CardContent sx={{ "&:last-child": { pb: 1 }, pt: 1 }}>
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <Typography variant="body2">* Q1: Dec., Jan. - Feb.</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">* Q2: Mar. - May</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">* Q3: June through Aug.</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">* Q4: Sept. through Nov.</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" sx={{ pb: 0 }}>
                * TTI - Travel Time Index
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" sx={{ pb: 0 }}>
                * PTI - Planning Time Index
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>{" "}
    </div>
  );
}
