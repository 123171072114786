import React, { useState, useRef, useEffect, useMemo, useContext } from "react";

// import { geographies, vehicleWeightClassOptions } from "../common/constants";

import Grid from "@material-ui/core/Grid";

// import HighStock from "./HighCharts/HighStock";
// import LineChart from "./Charts/HighCharts/LineChart";
// import StackedBarChart from "./HighCharts/StackedBarChart";
// import PieChart from "./Charts/HighCharts/PieChart";
import StackedGrouped3dBarChart from "./HighCharts/StackedGrouped3dBarChart";
import StackedGroupedBarChart from "./HighCharts/StackedGroupedBarChart";
// import Donut3dChart from "./HighCharts/Donut3dChart";
// import MultiLineChart from "./Charts/HighCharts/MultiLineChart";
// import DependencyWheel from "./Charts/HighCharts/DependencyWheel";
// import SankeyDiagram from "./HighCharts/SankeyDiagram";
// import TimeSeriesChart from "./HighCharts/TimeSeriesChart";
// import TimeSeriesWithLineChart from "./HighCharts/TimeSeriesWithLineChart";
// import ScatterPlot from "./HighCharts/ScatterPlot";
// import Column3dPlot from "./HighCharts/Column3dPlot";
import HighContrasLight from "highcharts/themes/high-contrast-light";

import { gridStyles } from "../ui/styles";

import { reportingYearsQuarters } from "../common/constants";

function ChartsLayout(props) {
  const classesGrid = gridStyles();
  //   const classes = useStyles();
  const { statusGuide, mobilityData } = props;

  const stageCanvasRef = useRef(null);
  const [chartHeight, setChartHeight] = useState("325vh");
  //   useEffect(() => {
  //     // The 'current' property contains info of the reference:
  //     // align, title, ... , width, height, etc.
  //     if (stageCanvasRef.current) {
  //       let height = stageCanvasRef.current.offsetHeight;
  //       let width = stageCanvasRef.current.offsetWidth;

  //       setChartHeight(stageCanvasRef.current.offsetHeight);
  //     }
  //   }, [stageCanvasRef]);

  const ttiDataOrder = [
    "TTI_AM_Mean",
    "TTI_MD_Mean",
    "TTI_PM_Mean",
    "TTI_ON_Mean",
    "TTI_ALL247_Mean",
    "TTI_WE_Mean",
    "TTI_PEAKS_Mean",
  ];
  const speedDataOrder = [
    "AvgSpeed_AM_Mean",
    "AvgSpeed_MD_Mean",
    "AvgSpeed_PM_Mean",
    "AvgSpeed_ON_Mean",
    "AvgSpeed_ALL247_Mean",
    "AvgSpeed_WE_Mean",
    "AvgSpeed_PEAKS_Mean",
  ];
  const pti95DataOrder = [
    "PTI95_AM_Mean",
    "PTI95_MD_Mean",
    "PTI95_PM_Mean",
    "PTI95_ON_Mean",
    "PTI95_ALL247_Mean",
    "PTI95_WE_Mean",
    "PTI95_PEAKS_Mean",
  ];
  const pti80DataOrder = [
    "PTI80_AM_Mean",
    "PTI80_MD_Mean",
    "PTI80_PM_Mean",
    "PTI80_ON_Mean",
    "PTI80_ALL247_Mean",
    "PTI80_WE_Mean",
    "PTI80_PEAKS_Mean",
  ];
  const [ttiChartData, setTtiChartData] = useState(null);
  const [agSpeedChartData, setAvgSpeedChartData] = useState(null);
  const [pti95ChartData, setPti95ChartData] = useState(null);
  const [pti80ChartData, setPti80ChartData] = useState(null);
  useEffect(() => {
    if (mobilityData) {
      let chartDataTemp = [];
      let dataTemp = [];
      reportingYearsQuarters.map((ryq) => {
        // ttiDataOrder.map((item) => {
        //   dataTemp.push(
        //     parseFloat(
        //       mobilityData.filter((obj) => obj.YYQQ === "2021Q3")[0][item]
        //     )
        //   );
        // });
        // chartDataTemp.push({
        //   name: "2020 Q3 (June through Aug.)",
        //   data: dataTemp,
        //   stack: "qp",
        // });
        // dataTemp = [];
        // ttiDataOrder.map((item) => {
        //   dataTemp.push(
        //     parseFloat(
        //       mobilityData.filter((obj) => obj.YYQQ === "2021Q4")[0][item]
        //     )
        //   );
        // });
        // chartDataTemp.push({
        //   name: "2020 Q4 (Sept. through Nov.)",
        //   data: dataTemp,
        //   stack: "qc",
        // });
        dataTemp = [];
        ttiDataOrder.map((item) => {
          dataTemp.push(
            parseFloat(
              mobilityData.filter((obj) => obj.YYQQ === ryq.abbrev)[0][item]
            )
          );
        });
        chartDataTemp.push({
          name: ryq.year + " " + ryq.quarter,
          data: dataTemp,
          stack: ryq.abbrev,
        });
      });
      setTtiChartData(chartDataTemp);

      chartDataTemp = [];
      dataTemp = [];
      reportingYearsQuarters.map((ryq) => {
        // speedDataOrder.map((item) => {
        //   dataTemp.push(
        //     parseFloat(
        //       mobilityData.filter((obj) => obj.YYQQ === "2021Q3")[0][item]
        //     )
        //   );
        // });
        // chartDataTemp.push({
        //   name: "2020 Q3 (June through Aug.)",
        //   data: dataTemp,
        //   stack: "qp",
        // });
        // dataTemp = [];
        // speedDataOrder.map((item) => {
        //   dataTemp.push(
        //     parseFloat(
        //       mobilityData.filter((obj) => obj.YYQQ === "2021Q4")[0][item]
        //     )
        //   );
        // });
        // chartDataTemp.push({
        //   name: "2020 Q4 (Sept. through Nov.)",
        //   data: dataTemp,
        //   stack: "qc",
        // });
        dataTemp = [];
        speedDataOrder.map((item) => {
          dataTemp.push(
            parseFloat(
              mobilityData.filter((obj) => obj.YYQQ === ryq.abbrev)[0][item]
            )
          );
        });
        chartDataTemp.push({
          name: ryq.year + " " + ryq.quarter,
          data: dataTemp,
          stack: ryq.abbrev,
        });
      });
      setAvgSpeedChartData(chartDataTemp);

      chartDataTemp = [];
      dataTemp = [];
      reportingYearsQuarters.map((ryq) => {
        // pti95DataOrder.map((item) => {
        //   dataTemp.push(
        //     parseFloat(
        //       mobilityData.filter((obj) => obj.YYQQ === "2021Q3")[0][item]
        //     )
        //   );
        // });
        // chartDataTemp.push({
        //   name: "2020 Q3 (June through Aug.)",
        //   data: dataTemp,
        //   stack: "qp",
        // });
        // dataTemp = [];
        // pti95DataOrder.map((item) => {
        //   dataTemp.push(
        //     parseFloat(
        //       mobilityData.filter((obj) => obj.YYQQ === "2021Q4")[0][item]
        //     )
        //   );
        // });
        // chartDataTemp.push({
        //   name: "2020 Q4 (Sept. through Nov.)",
        //   data: dataTemp,
        //   stack: "qc",
        // });
        dataTemp = [];
        pti95DataOrder.map((item) => {
          dataTemp.push(
            parseFloat(
              mobilityData.filter((obj) => obj.YYQQ === ryq.abbrev)[0][item]
            )
          );
        });
        chartDataTemp.push({
          name: ryq.year + " " + ryq.quarter,
          data: dataTemp,
          stack: ryq.abbrev,
        });
      });

      setPti95ChartData(chartDataTemp);

      chartDataTemp = [];
      dataTemp = [];
      reportingYearsQuarters.map((ryq) => {
        // pti80DataOrder.map((item) => {
        //   dataTemp.push(
        //     parseFloat(
        //       mobilityData.filter((obj) => obj.YYQQ === "2021Q3")[0][item]
        //     )
        //   );
        // });
        // chartDataTemp.push({
        //   name: "2020 Q3 (June through Aug.)",
        //   data: dataTemp,
        //   stack: "qp",
        // });
        // dataTemp = [];
        // pti80DataOrder.map((item) => {
        //   dataTemp.push(
        //     parseFloat(
        //       mobilityData.filter((obj) => obj.YYQQ === "2021Q4")[0][item]
        //     )
        //   );
        // });
        // chartDataTemp.push({
        //   name: "2020 Q4 (Sept. through Nov.)",
        //   data: dataTemp,
        //   stack: "qc",
        // });
        dataTemp = [];
        pti80DataOrder.map((item) => {
          dataTemp.push(
            parseFloat(
              mobilityData.filter((obj) => obj.YYQQ === ryq.abbrev)[0][item]
            )
          );
        });
        chartDataTemp.push({
          name: ryq.year + " " + ryq.quarter,
          data: dataTemp,
          stack: ryq.abbrev,
        });
      });
      setPti80ChartData(chartDataTemp);
    }
  }, [mobilityData]);
  // console.log("ttiChartData-check", ttiChartData);

  return (
    <div>
      <Grid
        container
        // spacing={0}
        // style={{ height: "41vh", marginTop: "12%" }}
        // className={classesGrid.gridRow1}
      >
        <Grid item xs={6} sx={{ mr: 20 }}>
          {ttiChartData && (
            <StackedGrouped3dBarChart
              chartHeight={chartHeight}
              //   chartHeight={"400vh"}
              chartData={ttiChartData}
              titleText={"Travel Time Index (TTI)"}
              xAxisCategories={[
                "AM Peak",
                "Midday",
                "PM Peak",
                "Overnight",
                "Daily",
                "Weekend Day",
                "Peaks",
              ]}
              // xAxisfontSize={12}
              yAxisTitleText={"TTI"}
              yAxisMin={1}
              enabledDataLabels={false}
              caption={
                "Q1: Dec. (prior year), Jan. - Feb., Q2: Mar. - May, Q3: June - Aug., Q4: Sept. - Nov."
              }
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {agSpeedChartData && (
            <StackedGrouped3dBarChart
              chartHeight={chartHeight}
              // chartData={[
              //   {
              //     name: "2020 Q4 (Sept. through Nov.)",
              //     data: [5, 3, 4, 7, 3, 4, 7],
              //     stack: "qc",
              //   },
              //   {
              //     name: "2020 Q3 (June through Aug.)",
              //     data: [4, 2, 3, 6, 2, 3, 6],
              //     stack: "qp",
              //   },
              // ]}
              chartData={agSpeedChartData}
              titleText={"Speed (mph)"}
              xAxisCategories={[
                "AM Peak",
                "Midday",
                "PM Peak",
                "Overnight",
                "Daily",
                "Weekend Day",
                "Peaks",
              ]}
              // xAxisfontSize={12}
              yAxisTitleText={"Average Speed (mph)"}
              yAxisMin={40}
              enabledDataLabels={false}
              caption={
                "Q1: Dec. (prior year), Jan. - Feb., Q2: Mar. - May, Q3: June - Aug., Q4: Sept. - Nov."
              }
            />
          )}
        </Grid>{" "}
      </Grid>
      <Grid
        container
        // spacing={0}
        // style={{ height: "41vh", marginTop: "12%" }}
        // className={classesGrid.gridRow1}
      >
        <Grid item xs={6}>
          {pti95ChartData && (
            <StackedGrouped3dBarChart
              chartHeight={chartHeight}
              // chartData={[
              //   {
              //     name: "2020 Q4 (Sept. through Nov.)",
              //     data: [5, 3, 4, 7, 3, 4, 7],
              //     stack: "qc",
              //   },
              //   {
              //     name: "2020 Q3 (June through Aug.)",
              //     data: [4, 2, 3, 6, 2, 3, 6],
              //     stack: "qp",
              //   },
              // ]}
              chartData={pti95ChartData}
              titleText={"Planning Time Index (PTI) - 95th Percentile"}
              xAxisCategories={[
                "AM Peak",
                "Midday",
                "PM Peak",
                "Overnight",
                "Daily",
                "Weekend Day",
                "Peaks",
              ]}
              // xAxisfontSize={12}
              yAxisTitleText={"PTI - 95th Percentile"}
              yAxisMin={1}
              enabledDataLabels={false}
              caption={
                "Q1: Dec. (prior year), Jan. - Feb., Q2: Mar. - May, Q3: June - Aug., Q4: Sept. - Nov."
              }
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {pti80ChartData && (
            <StackedGrouped3dBarChart
              chartHeight={chartHeight}
              chartData={pti80ChartData}
              titleText={"Planning Time Index (PTI) - 80th Percentile"}
              xAxisCategories={[
                "AM Peak",
                "Midday",
                "PM Peak",
                "Overnight",
                "Daily",
                "Weekend Day",
                "Peaks",
              ]}
              // xAxisfontSize={12}
              yAxisTitleText={"PTI - 80th Percentile"}
              yAxisMin={1}
              enabledDataLabels={false}
              caption={
                "Q1: Dec. (prior year), Jan. - Feb., Q2: Mar. - May, Q3: June - Aug., Q4: Sept. - Nov."
              }
            />
          )}
        </Grid>{" "}
      </Grid>{" "}
    </div>
  );
}

export default ChartsLayout;
