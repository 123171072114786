import React from "react";
import { render } from "react-dom";
// Import Highcharts
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);

export default function Bar3dChart(props) {
  const {
    themeHC,
    chartHeight,
    chartData,
    titleText,
    xAxisCategories,
    plotOptionsColumnStacking,
    plotOptionsColumnDataLabelsEndabled,
    chartRef3,
    seriesName,
    yAxisMax,
    subtitleText,
    yAxisTitleText,
    yAxisMin,
    enableLegend,
    enableExporting,
    xAxisfontSize,
    enabled3d,
  } = props;

  const options = {
    chart: {
      renderTo: "container",
      type: "column",
      // height: "335vh",
      height: chartHeight,
      options3d: {
        enabled: enabled3d,
        alpha: 15,
        beta: 15,
        depth: 100,
        viewDistance: 25,
      },
      zoomType: "xy",
    },
    title: {
      text: titleText,
    },
    subtitle: {
      text: subtitleText,
    },
    plotOptions: {
      column: {
        depth: 25,
      },
    },
    series: [
      {
        data: chartData,
      },
    ],
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: xAxisCategories,
      labels: {
        skew3d: true,
        style: {
          fontSize: xAxisfontSize,
        },
      },
    },
    yAxis: {
      allowDecimals: true,
      min: yAxisMin,
      title: {
        text: yAxisTitleText,
        skew3d: false,
      },
    },
    legend: { enabled: enableLegend },
    exporting: {
      enabled: enableExporting,
    },
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        updateArgs={[true]}
        ref={chartRef3}
      />
    </div>
  );
}
