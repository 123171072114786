export const MAPBOX_URL =
  "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={access_token}";
//"https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={access_token}";

export const MAPBOX_STREETS_V10_URL =
  "https://api.tiles.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}?access_token={access_token}";

export const MAPBOX_ATTRIBUTION = `Map data &copy; 
<a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, 
<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>,
Imagery © <a href="https://www.mapbox.com/">Mapbox</a>`;

export const baseLayers = [
  {
    name: "Streets",
    url: MAPBOX_STREETS_V10_URL,
    id: "mapbox/streets-v11",
  },
  {
    name: "Satellite",
    url: MAPBOX_URL,
    id: "mapbox/satellite-v9",
  },
  {
    name: "Satellite-Streets",
    url: MAPBOX_URL,
    id: "mapbox/satellite-streets-v11",
  },
  {
    name: "Light",
    url: MAPBOX_URL,
    id: "mapbox/light-v10",
  },
  {
    name: "Dark",
    url: MAPBOX_URL,
    id: "mapbox/dark-v10",
  },
];

export const reportingYearsQuarters = [
  { year: 2021, quarter: "Q3", abbrev: "2021Q3" },
  { year: 2021, quarter: "Q4", abbrev: "2021Q4" },
  { year: 2022, quarter: "Q1", abbrev: "2022Q1" },
  { year: 2022, quarter: "Q2", abbrev: "2022Q2" },
  { year: 2022, quarter: "Q3", abbrev: "2022Q3" },
  { year: 2022, quarter: "Q4", abbrev: "2022Q4" },
  { year: 2023, quarter: "Q1", abbrev: "2023Q1" },
];

export const geographies = [
  {
    category: "county",
    reportingArea: "ANDERSON",
    label: "Anderson County",
    route: "/AndersonCounty",
  },
  {
    category: "county",
    reportingArea: "ANDREWS",
    label: "Andrews County",
    route: "/AndrewsCounty",
  },
  {
    category: "county",
    reportingArea: "ANGELINA",
    label: "Angelina County",
    route: "/AngelinaCounty",
  },
  {
    category: "county",
    reportingArea: "ARCHER",
    label: "Archer County",
    route: "/ArcherCounty",
  },
  {
    category: "county",
    reportingArea: "ARMSTRONG",
    label: "Armstrong County",
    route: "/ArmstrongCounty",
  },
  {
    category: "county",
    reportingArea: "ATASCOSA",
    label: "Atascosa County",
    route: "/AtascosaCounty",
  },
  {
    category: "county",
    reportingArea: "AUSTIN",
    label: "Austin County",
    route: "/AustinCounty",
  },
  {
    category: "county",
    reportingArea: "BAILEY",
    label: "Bailey County",
    route: "/BaileyCounty",
  },
  {
    category: "county",
    reportingArea: "BASTROP",
    label: "Bastrop County",
    route: "/BastropCounty",
  },
  {
    category: "county",
    reportingArea: "BAYLOR",
    label: "Baylor County",
    route: "/BaylorCounty",
  },
  {
    category: "county",
    reportingArea: "BEE",
    label: "Bee County",
    route: "/BeeCounty",
  },
  {
    category: "county",
    reportingArea: "BELL",
    label: "Bell County",
    route: "/BellCounty",
  },
  {
    category: "county",
    reportingArea: "BEXAR",
    label: "Bexar County",
    route: "/BexarCounty",
  },
  {
    category: "county",
    reportingArea: "BLANCO",
    label: "Blanco County",
    route: "/BlancoCounty",
  },
  {
    category: "county",
    reportingArea: "BOSQUE",
    label: "Bosque County",
    route: "/BosqueCounty",
  },
  {
    category: "county",
    reportingArea: "BOWIE",
    label: "Bowie County",
    route: "/BowieCounty",
  },
  {
    category: "county",
    reportingArea: "BRAZORIA",
    label: "Brazoria County",
    route: "/BrazoriaCounty",
  },
  {
    category: "county",
    reportingArea: "BRAZOS",
    label: "Brazos County",
    route: "/BrazosCounty",
  },
  {
    category: "county",
    reportingArea: "BREWSTER",
    label: "Brewster County",
    route: "/BrewsterCounty",
  },
  {
    category: "county",
    reportingArea: "BROOKS",
    label: "Brooks County",
    route: "/BrooksCounty",
  },
  {
    category: "county",
    reportingArea: "BROWN",
    label: "Brown County",
    route: "/BrownCounty",
  },
  {
    category: "county",
    reportingArea: "BURLESON",
    label: "Burleson County",
    route: "/BurlesonCounty",
  },
  {
    category: "county",
    reportingArea: "BURNET",
    label: "Burnet County",
    route: "/BurnetCounty",
  },
  {
    category: "county",
    reportingArea: "CALDWELL",
    label: "Caldwell County",
    route: "/CaldwellCounty",
  },
  {
    category: "county",
    reportingArea: "CALHOUN",
    label: "Calhoun County",
    route: "/CalhounCounty",
  },
  {
    category: "county",
    reportingArea: "CALLAHAN",
    label: "Callahan County",
    route: "/CallahanCounty",
  },
  {
    category: "county",
    reportingArea: "CAMERON",
    label: "Cameron County",
    route: "/CameronCounty",
  },
  {
    category: "county",
    reportingArea: "CAMP",
    label: "Camp County",
    route: "/CampCounty",
  },
  {
    category: "county",
    reportingArea: "CARSON",
    label: "Carson County",
    route: "/CarsonCounty",
  },
  {
    category: "county",
    reportingArea: "CASS",
    label: "Cass County",
    route: "/CassCounty",
  },
  {
    category: "county",
    reportingArea: "CASTRO",
    label: "Castro County",
    route: "/CastroCounty",
  },
  {
    category: "county",
    reportingArea: "CHAMBERS",
    label: "Chambers County",
    route: "/ChambersCounty",
  },
  {
    category: "county",
    reportingArea: "CHEROKEE",
    label: "Cherokee County",
    route: "/CherokeeCounty",
  },
  {
    category: "county",
    reportingArea: "CHILDRESS",
    label: "Childress County",
    route: "/ChildressCounty",
  },
  {
    category: "county",
    reportingArea: "CLAY",
    label: "Clay County",
    route: "/ClayCounty",
  },
  {
    category: "county",
    reportingArea: "COKE",
    label: "Coke County",
    route: "/CokeCounty",
  },
  {
    category: "county",
    reportingArea: "COLEMAN",
    label: "Coleman County",
    route: "/ColemanCounty",
  },
  {
    category: "county",
    reportingArea: "COLLIN",
    label: "Collin County",
    route: "/CollinCounty",
  },
  {
    category: "county",
    reportingArea: "COLORADO",
    label: "Colorado County",
    route: "/ColoradoCounty",
  },
  {
    category: "county",
    reportingArea: "COMAL",
    label: "Comal County",
    route: "/ComalCounty",
  },
  {
    category: "county",
    reportingArea: "COMANCHE",
    label: "Comanche County",
    route: "/ComancheCounty",
  },
  {
    category: "county",
    reportingArea: "CONCHO",
    label: "Concho County",
    route: "/ConchoCounty",
  },
  {
    category: "county",
    reportingArea: "COOKE",
    label: "Cooke County",
    route: "/CookeCounty",
  },
  {
    category: "county",
    reportingArea: "CORYELL",
    label: "Coryell County",
    route: "/CoryellCounty",
  },
  {
    category: "county",
    reportingArea: "CRANE",
    label: "Crane County",
    route: "/CraneCounty",
  },
  {
    category: "county",
    reportingArea: "CROCKETT",
    label: "Crockett County",
    route: "/CrockettCounty",
  },
  {
    category: "county",
    reportingArea: "CROSBY",
    label: "Crosby County",
    route: "/CrosbyCounty",
  },
  {
    category: "county",
    reportingArea: "CULBERSON",
    label: "Culberson County",
    route: "/CulbersonCounty",
  },
  {
    category: "county",
    reportingArea: "DALLAM",
    label: "Dallam County",
    route: "/DallamCounty",
  },
  {
    category: "county",
    reportingArea: "DALLAS",
    label: "Dallas County",
    route: "/DallasCounty",
  },
  {
    category: "county",
    reportingArea: "DAWSON",
    label: "Dawson County",
    route: "/DawsonCounty",
  },
  {
    category: "county",
    reportingArea: "DE WITT",
    label: "De Witt County",
    route: "/DeWittCounty",
  },
  {
    category: "county",
    reportingArea: "DEAF SMITH",
    label: "Deaf Smith County",
    route: "/DeafSmithCounty",
  },
  {
    category: "county",
    reportingArea: "DELTA",
    label: "Delta County",
    route: "/DeltaCounty",
  },
  {
    category: "county",
    reportingArea: "DENTON",
    label: "Denton County",
    route: "/DentonCounty",
  },
  {
    category: "county",
    reportingArea: "DICKENS",
    label: "Dickens County",
    route: "/DickensCounty",
  },
  {
    category: "county",
    reportingArea: "DIMMIT",
    label: "Dimmit County",
    route: "/DimmitCounty",
  },
  {
    category: "county",
    reportingArea: "DONLEY",
    label: "Donley County",
    route: "/DonleyCounty",
  },
  {
    category: "county",
    reportingArea: "DUVAL",
    label: "Duval County",
    route: "/DuvalCounty",
  },
  {
    category: "county",
    reportingArea: "EASTLAND",
    label: "Eastland County",
    route: "/EastlandCounty",
  },
  {
    category: "county",
    reportingArea: "ECTOR",
    label: "Ector County",
    route: "/EctorCounty",
  },
  {
    category: "county",
    reportingArea: "EDWARDS",
    label: "Edwards County",
    route: "/EdwardsCounty",
  },
  {
    category: "county",
    reportingArea: "EL PASO",
    label: "El Paso County",
    route: "/ElPasoCounty",
  },
  {
    category: "county",
    reportingArea: "ELLIS",
    label: "Ellis County",
    route: "/EllisCounty",
  },
  {
    category: "county",
    reportingArea: "ERATH",
    label: "Erath County",
    route: "/ErathCounty",
  },
  {
    category: "county",
    reportingArea: "FALLS",
    label: "Falls County",
    route: "/FallsCounty",
  },
  {
    category: "county",
    reportingArea: "FANNIN",
    label: "Fannin County",
    route: "/FanninCounty",
  },
  {
    category: "county",
    reportingArea: "FAYETTE",
    label: "Fayette County",
    route: "/FayetteCounty",
  },
  {
    category: "county",
    reportingArea: "FORT BEND",
    label: "Fort Bend County",
    route: "/FortBendCounty",
  },
  {
    category: "county",
    reportingArea: "FRANKLIN",
    label: "Franklin County",
    route: "/FranklinCounty",
  },
  {
    category: "county",
    reportingArea: "FREESTONE",
    label: "Freestone County",
    route: "/FreestoneCounty",
  },
  {
    category: "county",
    reportingArea: "FRIO",
    label: "Frio County",
    route: "/FrioCounty",
  },
  {
    category: "county",
    reportingArea: "GAINES",
    label: "Gaines County",
    route: "/GainesCounty",
  },
  {
    category: "county",
    reportingArea: "GALVESTON",
    label: "Galveston County",
    route: "/GalvestonCounty",
  },
  {
    category: "county",
    reportingArea: "GARZA",
    label: "Garza County",
    route: "/GarzaCounty",
  },
  {
    category: "county",
    reportingArea: "GILLESPIE",
    label: "Gillespie County",
    route: "/GillespieCounty",
  },
  {
    category: "county",
    reportingArea: "GLASSCOCK",
    label: "Glasscock County",
    route: "/GlasscockCounty",
  },
  {
    category: "county",
    reportingArea: "GOLIAD",
    label: "Goliad County",
    route: "/GoliadCounty",
  },
  {
    category: "county",
    reportingArea: "GONZALES",
    label: "Gonzales County",
    route: "/GonzalesCounty",
  },
  {
    category: "county",
    reportingArea: "GRAY",
    label: "Gray County",
    route: "/GrayCounty",
  },
  {
    category: "county",
    reportingArea: "GRAYSON",
    label: "Grayson County",
    route: "/GraysonCounty",
  },
  {
    category: "county",
    reportingArea: "GREGG",
    label: "Gregg County",
    route: "/GreggCounty",
  },
  {
    category: "county",
    reportingArea: "GRIMES",
    label: "Grimes County",
    route: "/GrimesCounty",
  },
  {
    category: "county",
    reportingArea: "GUADALUPE",
    label: "Guadalupe County",
    route: "/GuadalupeCounty",
  },
  {
    category: "county",
    reportingArea: "HALE",
    label: "Hale County",
    route: "/HaleCounty",
  },
  {
    category: "county",
    reportingArea: "HALL",
    label: "Hall County",
    route: "/HallCounty",
  },
  {
    category: "county",
    reportingArea: "HAMILTON",
    label: "Hamilton County",
    route: "/HamiltonCounty",
  },
  {
    category: "county",
    reportingArea: "HARDEMAN",
    label: "Hardeman County",
    route: "/HardemanCounty",
  },
  {
    category: "county",
    reportingArea: "HARDIN",
    label: "Hardin County",
    route: "/HardinCounty",
  },
  {
    category: "county",
    reportingArea: "HARRIS",
    label: "Harris County",
    route: "/HarrisCounty",
  },
  {
    category: "county",
    reportingArea: "HARRISON",
    label: "Harrison County",
    route: "/HarrisonCounty",
  },
  {
    category: "county",
    reportingArea: "HARTLEY",
    label: "Hartley County",
    route: "/HartleyCounty",
  },
  {
    category: "county",
    reportingArea: "HASKELL",
    label: "Haskell County",
    route: "/HaskellCounty",
  },
  {
    category: "county",
    reportingArea: "HAYS",
    label: "Hays County",
    route: "/HaysCounty",
  },
  {
    category: "county",
    reportingArea: "HEMPHILL",
    label: "Hemphill County",
    route: "/HemphillCounty",
  },
  {
    category: "county",
    reportingArea: "HENDERSON",
    label: "Henderson County",
    route: "/HendersonCounty",
  },
  {
    category: "county",
    reportingArea: "HIDALGO",
    label: "Hidalgo County",
    route: "/HidalgoCounty",
  },
  {
    category: "county",
    reportingArea: "HILL",
    label: "Hill County",
    route: "/HillCounty",
  },
  {
    category: "county",
    reportingArea: "HOCKLEY",
    label: "Hockley County",
    route: "/HockleyCounty",
  },
  {
    category: "county",
    reportingArea: "HOOD",
    label: "Hood County",
    route: "/HoodCounty",
  },
  {
    category: "county",
    reportingArea: "HOPKINS",
    label: "Hopkins County",
    route: "/HopkinsCounty",
  },
  {
    category: "county",
    reportingArea: "HOUSTON",
    label: "Houston County",
    route: "/HoustonCounty",
  },
  {
    category: "county",
    reportingArea: "HOWARD",
    label: "Howard County",
    route: "/HowardCounty",
  },
  {
    category: "county",
    reportingArea: "HUDSPETH",
    label: "Hudspeth County",
    route: "/HudspethCounty",
  },
  {
    category: "county",
    reportingArea: "HUNT",
    label: "Hunt County",
    route: "/HuntCounty",
  },
  {
    category: "county",
    reportingArea: "JACK",
    label: "Jack County",
    route: "/JackCounty",
  },
  {
    category: "county",
    reportingArea: "JACKSON",
    label: "Jackson County",
    route: "/JacksonCounty",
  },
  {
    category: "county",
    reportingArea: "JASPER",
    label: "Jasper County",
    route: "/JasperCounty",
  },
  {
    category: "county",
    reportingArea: "JEFF DAVIS",
    label: "Jeff Davis County",
    route: "/JeffDavisCounty",
  },
  {
    category: "county",
    reportingArea: "JEFFERSON",
    label: "Jefferson County",
    route: "/JeffersonCounty",
  },
  {
    category: "county",
    reportingArea: "JIM WELLS",
    label: "Jim Wells County",
    route: "/JimWellsCounty",
  },
  {
    category: "county",
    reportingArea: "JOHNSON",
    label: "Johnson County",
    route: "/JohnsonCounty",
  },
  {
    category: "county",
    reportingArea: "JONES",
    label: "Jones County",
    route: "/JonesCounty",
  },
  {
    category: "county",
    reportingArea: "KARNES",
    label: "Karnes County",
    route: "/KarnesCounty",
  },
  {
    category: "county",
    reportingArea: "KAUFMAN",
    label: "Kaufman County",
    route: "/KaufmanCounty",
  },
  {
    category: "county",
    reportingArea: "KENDALL",
    label: "Kendall County",
    route: "/KendallCounty",
  },
  {
    category: "county",
    reportingArea: "KENEDY",
    label: "Kenedy County",
    route: "/KenedyCounty",
  },
  {
    category: "county",
    reportingArea: "KERR",
    label: "Kerr County",
    route: "/KerrCounty",
  },
  {
    category: "county",
    reportingArea: "KIMBLE",
    label: "Kimble County",
    route: "/KimbleCounty",
  },
  {
    category: "county",
    reportingArea: "KING",
    label: "King County",
    route: "/KingCounty",
  },
  {
    category: "county",
    reportingArea: "KINNEY",
    label: "Kinney County",
    route: "/KinneyCounty",
  },
  {
    category: "county",
    reportingArea: "KLEBERG",
    label: "Kleberg County",
    route: "/KlebergCounty",
  },
  {
    category: "county",
    reportingArea: "KNOX",
    label: "Knox County",
    route: "/KnoxCounty",
  },
  {
    category: "county",
    reportingArea: "LA SALLE",
    label: "La Salle County",
    route: "/LaSalleCounty",
  },
  {
    category: "county",
    reportingArea: "LAMAR",
    label: "Lamar County",
    route: "/LamarCounty",
  },
  {
    category: "county",
    reportingArea: "LAMB",
    label: "Lamb County",
    route: "/LambCounty",
  },
  {
    category: "county",
    reportingArea: "LAMPASAS",
    label: "Lampasas County",
    route: "/LampasasCounty",
  },
  {
    category: "county",
    reportingArea: "LEE",
    label: "Lee County",
    route: "/LeeCounty",
  },
  {
    category: "county",
    reportingArea: "LEON",
    label: "Leon County",
    route: "/LeonCounty",
  },
  {
    category: "county",
    reportingArea: "LIBERTY",
    label: "Liberty County",
    route: "/LibertyCounty",
  },
  {
    category: "county",
    reportingArea: "LIPSCOMB",
    label: "Lipscomb County",
    route: "/LipscombCounty",
  },
  {
    category: "county",
    reportingArea: "LIVE OAK",
    label: "Live Oak County",
    route: "/LiveOakCounty",
  },
  {
    category: "county",
    reportingArea: "LUBBOCK",
    label: "Lubbock County",
    route: "/LubbockCounty",
  },
  {
    category: "county",
    reportingArea: "LYNN",
    label: "Lynn County",
    route: "/LynnCounty",
  },
  {
    category: "county",
    reportingArea: "MADISON",
    label: "Madison County",
    route: "/MadisonCounty",
  },
  {
    category: "county",
    reportingArea: "MARION",
    label: "Marion County",
    route: "/MarionCounty",
  },
  {
    category: "county",
    reportingArea: "MARTIN",
    label: "Martin County",
    route: "/MartinCounty",
  },
  {
    category: "county",
    reportingArea: "MAVERICK",
    label: "Maverick County",
    route: "/MaverickCounty",
  },
  {
    category: "county",
    reportingArea: "MCCULLOCH",
    label: "Mcculloch County",
    route: "/MccullochCounty",
  },
  {
    category: "county",
    reportingArea: "MCLENNAN",
    label: "Mclennan County",
    route: "/MclennanCounty",
  },
  {
    category: "county",
    reportingArea: "MCMULLEN",
    label: "Mcmullen County",
    route: "/McmullenCounty",
  },
  {
    category: "county",
    reportingArea: "MEDINA",
    label: "Medina County",
    route: "/MedinaCounty",
  },
  {
    category: "county",
    reportingArea: "MIDLAND",
    label: "Midland County",
    route: "/MidlandCounty",
  },
  {
    category: "county",
    reportingArea: "MILAM",
    label: "Milam County",
    route: "/MilamCounty",
  },
  {
    category: "county",
    reportingArea: "MILLS",
    label: "Mills County",
    route: "/MillsCounty",
  },
  {
    category: "county",
    reportingArea: "MITCHELL",
    label: "Mitchell County",
    route: "/MitchellCounty",
  },
  {
    category: "county",
    reportingArea: "MONTAGUE",
    label: "Montague County",
    route: "/MontagueCounty",
  },
  {
    category: "county",
    reportingArea: "MONTGOMERY",
    label: "Montgomery County",
    route: "/MontgomeryCounty",
  },
  {
    category: "county",
    reportingArea: "MOORE",
    label: "Moore County",
    route: "/MooreCounty",
  },
  {
    category: "county",
    reportingArea: "MORRIS",
    label: "Morris County",
    route: "/MorrisCounty",
  },
  {
    category: "county",
    reportingArea: "NACOGDOCHES",
    label: "Nacogdoches County",
    route: "/NacogdochesCounty",
  },
  {
    category: "county",
    reportingArea: "NAVARRO",
    label: "Navarro County",
    route: "/NavarroCounty",
  },
  {
    category: "county",
    reportingArea: "NEWTON",
    label: "Newton County",
    route: "/NewtonCounty",
  },
  {
    category: "county",
    reportingArea: "NOLAN",
    label: "Nolan County",
    route: "/NolanCounty",
  },
  {
    category: "county",
    reportingArea: "NUECES",
    label: "Nueces County",
    route: "/NuecesCounty",
  },
  {
    category: "county",
    reportingArea: "OLDHAM",
    label: "Oldham County",
    route: "/OldhamCounty",
  },
  {
    category: "county",
    reportingArea: "ORANGE",
    label: "Orange County",
    route: "/OrangeCounty",
  },
  {
    category: "county",
    reportingArea: "PALO PINTO",
    label: "Palo Pinto County",
    route: "/PaloPintoCounty",
  },
  {
    category: "county",
    reportingArea: "PANOLA",
    label: "Panola County",
    route: "/PanolaCounty",
  },
  {
    category: "county",
    reportingArea: "PARKER",
    label: "Parker County",
    route: "/ParkerCounty",
  },
  {
    category: "county",
    reportingArea: "PARMER",
    label: "Parmer County",
    route: "/ParmerCounty",
  },
  {
    category: "county",
    reportingArea: "PECOS",
    label: "Pecos County",
    route: "/PecosCounty",
  },
  {
    category: "county",
    reportingArea: "POLK",
    label: "Polk County",
    route: "/PolkCounty",
  },
  {
    category: "county",
    reportingArea: "POTTER",
    label: "Potter County",
    route: "/PotterCounty",
  },
  {
    category: "county",
    reportingArea: "PRESIDIO",
    label: "Presidio County",
    route: "/PresidioCounty",
  },
  {
    category: "county",
    reportingArea: "RAINS",
    label: "Rains County",
    route: "/RainsCounty",
  },
  {
    category: "county",
    reportingArea: "RANDALL",
    label: "Randall County",
    route: "/RandallCounty",
  },
  {
    category: "county",
    reportingArea: "RED RIVER",
    label: "Red River County",
    route: "/RedRiverCounty",
  },
  {
    category: "county",
    reportingArea: "REEVES",
    label: "Reeves County",
    route: "/ReevesCounty",
  },
  {
    category: "county",
    reportingArea: "REFUGIO",
    label: "Refugio County",
    route: "/RefugioCounty",
  },
  {
    category: "county",
    reportingArea: "ROBERTS",
    label: "Roberts County",
    route: "/RobertsCounty",
  },
  {
    category: "county",
    reportingArea: "ROBERTSON",
    label: "Robertson County",
    route: "/RobertsonCounty",
  },
  {
    category: "county",
    reportingArea: "ROCKWALL",
    label: "Rockwall County",
    route: "/RockwallCounty",
  },
  {
    category: "county",
    reportingArea: "RUNNELS",
    label: "Runnels County",
    route: "/RunnelsCounty",
  },
  {
    category: "county",
    reportingArea: "RUSK",
    label: "Rusk County",
    route: "/RuskCounty",
  },
  {
    category: "county",
    reportingArea: "SABINE",
    label: "Sabine County",
    route: "/SabineCounty",
  },
  {
    category: "county",
    reportingArea: "SAN AUGUSTINE",
    label: "San Augustine County",
    route: "/SanAugustineCounty",
  },
  {
    category: "county",
    reportingArea: "SAN JACINTO",
    label: "San Jacinto County",
    route: "/SanJacintoCounty",
  },
  {
    category: "county",
    reportingArea: "SAN PATRICIO",
    label: "San Patricio County",
    route: "/SanPatricioCounty",
  },
  {
    category: "county",
    reportingArea: "SAN SABA",
    label: "San Saba County",
    route: "/SanSabaCounty",
  },
  {
    category: "county",
    reportingArea: "SCHLEICHER",
    label: "Schleicher County",
    route: "/SchleicherCounty",
  },
  {
    category: "county",
    reportingArea: "SCURRY",
    label: "Scurry County",
    route: "/ScurryCounty",
  },
  {
    category: "county",
    reportingArea: "SHELBY",
    label: "Shelby County",
    route: "/ShelbyCounty",
  },
  {
    category: "county",
    reportingArea: "SHERMAN",
    label: "Sherman County",
    route: "/ShermanCounty",
  },
  {
    category: "county",
    reportingArea: "SMITH",
    label: "Smith County",
    route: "/SmithCounty",
  },
  {
    category: "county",
    reportingArea: "SOMERVELL",
    label: "Somervell County",
    route: "/SomervellCounty",
  },
  {
    category: "county",
    reportingArea: "STARR",
    label: "Starr County",
    route: "/StarrCounty",
  },
  {
    category: "county",
    reportingArea: "STERLING",
    label: "Sterling County",
    route: "/SterlingCounty",
  },
  {
    category: "county",
    reportingArea: "SUTTON",
    label: "Sutton County",
    route: "/SuttonCounty",
  },
  {
    category: "county",
    reportingArea: "SWISHER",
    label: "Swisher County",
    route: "/SwisherCounty",
  },
  {
    category: "county",
    reportingArea: "TARRANT",
    label: "Tarrant County",
    route: "/TarrantCounty",
  },
  {
    category: "county",
    reportingArea: "TAYLOR",
    label: "Taylor County",
    route: "/TaylorCounty",
  },
  {
    category: "county",
    reportingArea: "TERRELL",
    label: "Terrell County",
    route: "/TerrellCounty",
  },
  {
    category: "county",
    reportingArea: "TERRY",
    label: "Terry County",
    route: "/TerryCounty",
  },
  {
    category: "county",
    reportingArea: "TITUS",
    label: "Titus County",
    route: "/TitusCounty",
  },
  {
    category: "county",
    reportingArea: "TOM GREEN",
    label: "Tom Green County",
    route: "/TomGreenCounty",
  },
  {
    category: "county",
    reportingArea: "TRAVIS",
    label: "Travis County",
    route: "/TravisCounty",
  },
  {
    category: "county",
    reportingArea: "TYLER",
    label: "Tyler County",
    route: "/TylerCounty",
  },
  {
    category: "county",
    reportingArea: "UPSHUR",
    label: "Upshur County",
    route: "/UpshurCounty",
  },
  {
    category: "county",
    reportingArea: "UPTON",
    label: "Upton County",
    route: "/UptonCounty",
  },
  {
    category: "county",
    reportingArea: "UVALDE",
    label: "Uvalde County",
    route: "/UvaldeCounty",
  },
  {
    category: "county",
    reportingArea: "VAL VERDE",
    label: "Val Verde County",
    route: "/ValVerdeCounty",
  },
  {
    category: "county",
    reportingArea: "VAN ZANDT",
    label: "Van Zandt County",
    route: "/VanZandtCounty",
  },
  {
    category: "county",
    reportingArea: "VICTORIA",
    label: "Victoria County",
    route: "/VictoriaCounty",
  },
  {
    category: "county",
    reportingArea: "WALKER",
    label: "Walker County",
    route: "/WalkerCounty",
  },
  {
    category: "county",
    reportingArea: "WALLER",
    label: "Waller County",
    route: "/WallerCounty",
  },
  {
    category: "county",
    reportingArea: "WARD",
    label: "Ward County",
    route: "/WardCounty",
  },
  {
    category: "county",
    reportingArea: "WASHINGTON",
    label: "Washington County",
    route: "/WashingtonCounty",
  },
  {
    category: "county",
    reportingArea: "WEBB",
    label: "Webb County",
    route: "/WebbCounty",
  },
  {
    category: "county",
    reportingArea: "WHARTON",
    label: "Wharton County",
    route: "/WhartonCounty",
  },
  {
    category: "county",
    reportingArea: "WHEELER",
    label: "Wheeler County",
    route: "/WheelerCounty",
  },
  {
    category: "county",
    reportingArea: "WICHITA",
    label: "Wichita County",
    route: "/WichitaCounty",
  },
  {
    category: "county",
    reportingArea: "WILBARGER",
    label: "Wilbarger County",
    route: "/WilbargerCounty",
  },
  {
    category: "county",
    reportingArea: "WILLACY",
    label: "Willacy County",
    route: "/WillacyCounty",
  },
  {
    category: "county",
    reportingArea: "WILLIAMSON",
    label: "Williamson County",
    route: "/WilliamsonCounty",
  },
  {
    category: "county",
    reportingArea: "WILSON",
    label: "Wilson County",
    route: "/WilsonCounty",
  },
  {
    category: "county",
    reportingArea: "WISE",
    label: "Wise County",
    route: "/WiseCounty",
  },
  {
    category: "county",
    reportingArea: "WOOD",
    label: "Wood County",
    route: "/WoodCounty",
  },
  {
    category: "county",
    reportingArea: "YOUNG",
    label: "Young County",
    route: "/YoungCounty",
  },
  {
    category: "county",
    reportingArea: "ZAPATA",
    label: "Zapata County",
    route: "/ZapataCounty",
  },
  {
    category: "county",
    reportingArea: "ZAVALA",
    label: "Zavala County",
    route: "/ZavalaCounty",
  },
  {
    category: "district",
    reportingArea: "01-PAR",
    label: "Paris District",
    route: "/ParisDistrict",
    counties: [60, 75, 81, 92, 113, 117, 139, 190, 194],
  },
  {
    category: "district",
    reportingArea: "02-FTW",
    label: "Fort Worth District",
    route: "/FortWorthDistrict",
    counties: [73, 112, 120, 127, 182, 184, 213, 220, 249],
  },
  {
    category: "district",
    reportingArea: "03-WFS",
    label: "Wichita Falls District",
    route: "/WichitaFallsDistrict",
    counties: [5, 12, 39, 49, 169, 224, 243, 244, 252],
  },
  {
    category: "district",
    reportingArea: "04-AMA",
    label: "Amarillo District",
    route: "/AmarilloDistrict",
    counties: [
      6, 33, 56, 59, 91, 99, 104, 107, 118, 148, 171, 179, 180, 188, 191, 197,
      211,
    ],
  },
  {
    category: "district",
    reportingArea: "05-LBB",
    label: "Lubbock District",
    route: "/LubbockDistrict",
    counties: [
      9, 35, 40, 54, 58, 78, 84, 86, 96, 111, 140, 152, 153, 185, 219, 223, 251,
    ],
  },
  {
    category: "district",
    reportingArea: "06-ODA",
    label: "Odessa District",
    route: "/OdessaDistrict",
    counties: [2, 52, 69, 151, 156, 165, 186, 195, 222, 231, 238, 248],
  },
  {
    category: "district",
    reportingArea: "07-SJT",
    label: "San Angelo District",
    route: "/SanAngeloDistrict",
    counties: [
      41, 48, 53, 70, 88, 119, 134, 164, 192, 193, 200, 207, 216, 218, 226,
    ],
  },
  {
    category: "district",
    reportingArea: "08-ABL",
    label: "Abilene District",
    route: "/AbileneDistrict",
    counties: [17, 30, 77, 105, 115, 128, 132, 168, 177, 208, 209, 217, 221],
  },
  {
    category: "district",
    reportingArea: "09-WAC",
    label: "Waco District",
    route: "/WacoDistrict",
    counties: [14, 18, 50, 74, 98, 110, 147, 161],
  },
  {
    category: "district",
    reportingArea: "10-TYL",
    label: "Tyler District",
    route: "/TylerDistrict",
    counties: [1, 37, 93, 108, 201, 212, 234, 250],
  },
  {
    category: "district",
    reportingArea: "11-LFK",
    label: "Lufkin District",
    route: "/LufkinDistrict",
    counties: [114, 174, 187, 202, 203, 204, 210, 228, 3],
  },
  {
    category: "district",
    reportingArea: "12-HOU",
    label: "Houston District",
    route: "/HoustonDistrict",
    counties: [102, 170, 20, 237, 80, 85],
  },
  {
    category: "district",
    reportingArea: "13-YKM",
    label: "Yoakum District",
    route: "/YoakumDistrict",
    counties: [121, 143, 158, 235, 241, 29, 45, 62, 76, 8, 90],
  },
  {
    category: "district",
    reportingArea: "14-AUS",
    label: "Austin District",
    route: "/AustinDistrict",
    counties: [106, 11, 144, 150, 157, 16, 246, 227, 27, 28, 87],
  },
  {
    category: "district",
    reportingArea: "15-SAT",
    label: "San Antonio District",
    route: "/SanAntonioDistrict",
    counties: [10, 131, 133, 15, 162, 163, 247, 232, 46, 7, 83, 95],
  },
  {
    category: "district",
    reportingArea: "16-CRP",
    label: "Corpus Christi District",
    route: "/CorpusChristiDistrict",
    counties: [126, 129, 13, 137, 149, 178, 196, 205, 4, 89],
  },
  {
    category: "district",
    reportingArea: "17-BRY",
    label: "Bryan District",
    route: "/BryanDistrict",
    counties: [145, 154, 166, 198, 21, 236, 239, 26, 82, 94],
  },
  {
    category: "district",
    reportingArea: "18-DAL",
    label: "Dallas District",
    route: "/DallasDistrict",
    counties: [71, 130, 175, 199, 43, 57, 61],
  },
  {
    category: "district",
    reportingArea: "19-ATL",
    label: "Atlanta District",
    route: "/AtlantaDistrict",
    counties: [103, 155, 172, 183, 19, 225, 230, 32, 34],
  },
  {
    category: "district",
    reportingArea: "20-BMT",
    label: "Beaumont District",
    route: "/BeaumontDistrict",
    counties: [101, 122, 124, 146, 176, 181, 229, 36],
  },
  {
    category: "district",
    reportingArea: "21-PHR",
    label: "Pharr District",
    route: "/PharrDistrict",
    counties: [109, 125, 245, 214, 24, 253, 31, 66],
  },
  {
    category: "district",
    reportingArea: "22-LRD",
    label: "Laredo District",
    route: "/LaredoDistrict",
    counties: [136, 142, 159, 233, 240, 254, 64, 67],
  },
  {
    category: "district",
    reportingArea: "23-BWD",
    label: "Brownwood District",
    route: "/BrownwoodDistrict",
    counties: [141, 160, 167, 206, 215, 25, 42, 47, 68],
  },
  {
    category: "district",
    reportingArea: "24-ELP",
    label: "El Paso District",
    route: "/ElPasoDistrict",
    counties: [116, 123, 189, 22, 55, 72],
  },
  {
    category: "district",
    reportingArea: "25-CHS",
    label: "Childress District",
    route: "/ChildressDistrict",
    counties: [100, 135, 138, 173, 242, 23, 38, 44, 51, 63, 65, 79, 97],
  },
  {
    category: "region",
    reportingArea: "Region-Border",
    label: "Border Region",
    route: "/BorderRegion",
    counties: [
      22, 31, 55, 72, 109, 116, 123, 136, 159, 189, 214, 222, 233, 240, 253,
    ],
  },
  {
    category: "region",
    reportingArea: "Region-DFW",
    label: "DFW Region",
    route: "/DFWRegion",
    counties: [
      43, 57, 61, 71, 73, 112, 117, 127, 130, 175, 182, 184, 199, 213, 220, 249,
    ],
  },
  {
    category: "region",
    reportingArea: "Region-Houston",
    label: "Houston Region",
    route: "/HoustonRegion",
    counties: [8, 20, 36, 45, 80, 102, 146, 158, 170, 236, 237, 241],
  },
  {
    category: "region",
    reportingArea: "Region-Permian Basin",
    label: "Permian Basin Region",
    route: "/PermianBasinRegion",
    counties: [
      2, 17, 22, 40, 41, 48, 51, 52, 53, 54, 55, 58, 63, 69, 70, 77, 78, 84, 86,
      88, 96, 111, 115, 116, 119, 123, 132, 134, 135, 138, 140, 151, 152, 153,
      156, 160, 164, 165, 168, 173, 177, 186, 189, 192, 195, 200, 207, 208, 216,
      217, 218, 221, 222, 223, 226, 231, 233, 238, 248, 251,
    ],
  },
  {
    category: "region",
    reportingArea: "Region-Rio Grande",
    label: "Rio Grande Region",
    route: "/RioGrandeRegion",
    counties: [24, 31, 66, 109, 125, 214, 245, 253],
  },
  {
    category: "region",
    reportingArea: "Region-Texas Triangle",
    label: "Texas Triange Region",
    route: "/TexasTriangeRegion",
    counties: [
      8, 14, 15, 18, 28, 43, 45, 46, 50, 57, 61, 71, 74, 76, 80, 82, 90, 95, 98,
      102, 106, 110, 127, 145, 147, 154, 161, 170, 175, 220, 227, 236, 237, 246,
    ],
  },
  {
    category: "state",
    reportingArea: "Texas Statewide",
    label: "Statewide",
    route: "/Statewide",
  },
];
