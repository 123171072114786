/**
 * @fileOverview the main UI component
 */

import React, { useState, useRef, useEffect, useMemo } from "react";

import logo from "../logo.svg";
import "../App.css";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeTheme, sidebarStyles, gridStyles } from "../ui/styles";
import "../App.scss";
// import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TablePanel from "../tables/TablePanel";
import DeckglMap from "../maps/DeckglMap";

import ChartsLayout from "../charts/ChartsLayout";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import Box from "@mui/material/Box";

import StackedGrouped3dBarChart from "../charts/HighCharts/StackedGrouped3dBarChart";
import Bar3dChart from "../charts/HighCharts/Bar3dChart";

import { reportingYearsQuarters } from "../common/constants";

function MainLayout(props) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mode = prefersDarkMode ? "dark" : "light";
  let theme = makeTheme(mode);
  theme = responsiveFontSizes(theme);
  const classes = sidebarStyles();
  const classesGrid = gridStyles();

  // const [statusGuide, setStatusGuide] = useState("Western Region");
  const { statusGuide, mobilityData, printRef, deckRef } = props;

  const [ttiCurrentPreviousQt, setTtiCurrentPreviousQt] = useState({
    current: 0,
    previous: 0,
    difference: 0,
    pcDifference: 0,
  });
  const [speedCurrentPreviousQt, setSpeedCurrentPreviousQt] = useState({
    current: 0,
    previous: 0,
    difference: 0,
    pcDifference: 0,
  });
  const [pti95CurrentPreviousQt, setPti95CurrentPreviousQt] = useState({
    current: 0,
    previous: 0,
    difference: 0,
    pcDifference: 0,
  });
  const [pti80CurrentPreviousQt, setPti80CurrentPreviousQt] = useState({
    current: 0,
    previous: 0,
    difference: 0,
    pcDifference: 0,
  });
  useEffect(() => {
    if (mobilityData) {
      let currentYearQuarter =
        reportingYearsQuarters[reportingYearsQuarters.length - 1].abbrev;
      let previousYearQuarter =
        reportingYearsQuarters[reportingYearsQuarters.length - 2].abbrev;
      let currentValue = parseFloat(
        mobilityData.filter((obj) => obj.YYQQ === currentYearQuarter)[0][
          "TTI_ALL247_Mean"
        ]
      );
      let previousValue = parseFloat(
        mobilityData.filter((obj) => obj.YYQQ === previousYearQuarter)[0][
          "TTI_ALL247_Mean"
        ]
      );
      let differenceValue = currentValue - previousValue;
      let pcDifferenceValue = (differenceValue / previousValue) * 100;
      setTtiCurrentPreviousQt({
        current: currentValue,
        previous: previousValue,
        difference: differenceValue,
        pcDifference: pcDifferenceValue,
      });

      currentValue = parseFloat(
        mobilityData.filter((obj) => obj.YYQQ === currentYearQuarter)[0][
          "AvgSpeed_ALL247_Mean"
        ]
      );
      previousValue = parseFloat(
        mobilityData.filter((obj) => obj.YYQQ === previousYearQuarter)[0][
          "AvgSpeed_ALL247_Mean"
        ]
      );
      differenceValue = currentValue - previousValue;
      pcDifferenceValue = (differenceValue / previousValue) * 100;
      setSpeedCurrentPreviousQt({
        current: currentValue,
        previous: previousValue,
        difference: differenceValue,
        pcDifference: pcDifferenceValue,
      });

      currentValue = parseFloat(
        mobilityData.filter((obj) => obj.YYQQ === currentYearQuarter)[0][
          "PTI95_ALL247_Mean"
        ]
      );
      previousValue = parseFloat(
        mobilityData.filter((obj) => obj.YYQQ === previousYearQuarter)[0][
          "PTI95_ALL247_Mean"
        ]
      );
      differenceValue = currentValue - previousValue;
      pcDifferenceValue = (differenceValue / previousValue) * 100;
      setPti95CurrentPreviousQt({
        current: currentValue,
        previous: previousValue,
        difference: differenceValue,
        pcDifference: pcDifferenceValue,
      });

      currentValue = parseFloat(
        mobilityData.filter((obj) => obj.YYQQ === currentYearQuarter)[0][
          "PTI80_ALL247_Mean"
        ]
      );
      previousValue = parseFloat(
        mobilityData.filter((obj) => obj.YYQQ === previousYearQuarter)[0][
          "PTI80_ALL247_Mean"
        ]
      );
      differenceValue = currentValue - previousValue;
      pcDifferenceValue = (differenceValue / previousValue) * 100;
      setPti80CurrentPreviousQt({
        current: currentValue,
        previous: previousValue,
        difference: differenceValue,
        pcDifference: pcDifferenceValue,
      });
    }
  }, [mobilityData]);

  function SetCardArrow(props) {
    const { type, diffValue } = props;
    if (type === "Speed") {
      if (diffValue >= 0) {
        return <ArrowUpwardIcon color="success" fontSize="medium" />;
      } else {
        return <ArrowDownwardIcon color="error" fontSize="medium" />;
      }
    } else {
      if (diffValue > 0) {
        return <ArrowUpwardIcon color="error" fontSize="medium" />;
      } else {
        return <ArrowDownwardIcon color="success" fontSize="medium" />;
      }
    }
  }

  function SetCardSubtitle(props) {
    const { type, diffValue } = props;
    if (type === "Speed") {
      if (diffValue >= 0) {
        return (
          <Typography variant="h5" style={{ color: "#2e7d32" }}>
            {diffValue.toFixed(2)} mph
          </Typography>
        );
      } else {
        return (
          <Typography variant="h5" color="error">
            {diffValue.toFixed(2)} mph
          </Typography>
        );
      }
    } else {
      if (diffValue > 0) {
        return (
          <Typography variant="h5" color="error">
            {diffValue.toFixed(2) * 100} Points
          </Typography>
        );
      } else {
        return (
          <Typography variant="h5" style={{ color: "#2e7d32" }}>
            {diffValue.toFixed(2) * 100} Points
          </Typography>
        );
      }
    }
  }

  function setChartData(category) {
    if (mobilityData) {
      let tempData = [];
      // reportingYearsQuarters[reportingYearsQuarters.length - 1].abbrev;
      reportingYearsQuarters.map((itm) => {
        let tempValue = parseFloat(
          mobilityData.filter((obj) => obj.YYQQ === itm.abbrev)[0][category]
        );
        // console.log("tempValue-check", tempValue);
        tempData.push(tempValue);
      });
      // console.log("tempData-check", tempData);
      return tempData;
    }
  }

  function setChartXaxis() {
    let tempData = [];
    // reportingYearsQuarters[reportingYearsQuarters.length - 1].abbrev;
    reportingYearsQuarters.map((itm) => {
      let tempValue = itm.year + " " + itm.quarter;
      tempData.push(tempValue);
    });

    return tempData;
  }

  return (
    <div className="site">
      {/* <QueryProvider> */}
      <Grid
        container
        spacing={0}
        className={classesGrid.gridRow1}
        ref={printRef}
      >
        <Grid item xs={2}>
          <Box
            height="8vh"
            width="8vw"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            sx={{ mb: 1, mt: 1 }}
          >
            <img
              className="logo"
              src="Transparent Background Color3lineLOGO_TxDOT_RGB_300dpi.png"
              alt="TxDOT Logo"
              title="TxDOT Logo"
              style={{ width: "8vw", height: "8vh" }}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={8}
          align="center"
          justify="center"
          sx={{ padding: 0, margin: 0 }}
        >
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography variant="h4" noWrap>
              {statusGuide.label +
                " Quarterly Truck Mobility Report - " +
                reportingYearsQuarters[reportingYearsQuarters.length - 1].year +
                " " +
                reportingYearsQuarters[reportingYearsQuarters.length - 1]
                  .quarter}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2} align="right">
          <Box
            height="8.5vh"
            width="11vw"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            sx={{ mr: 2, mt: 1 }}
          >
            {/* <Typography variant="caption" noWrap>
                    Developed By:
                  </Typography> */}
            <img
              // className="logo"
              src={"TTI-Color.png"}
              alt="TTI Logo"
              title="TTI Logo"
            />
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Card sx={{ mr: 1 }}>
            <CardHeader
              title="Travel Time Index (TTI)"
              // subheader="Current Quarter"
              subheader={
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justify: "center",
                  }}
                >
                  <SetCardArrow
                    type={"TTI"}
                    diffValue={ttiCurrentPreviousQt.difference}
                  />
                  <SetCardSubtitle
                    type={"TTI"}
                    diffValue={ttiCurrentPreviousQt.difference}
                  />
                </Box>
              }
            />
            <CardContent>
              <Bar3dChart
                chartHeight={"335vh"}
                // chartData={[
                //   ttiCurrentPreviousQt.previous,
                //   ttiCurrentPreviousQt.current,
                // ]}
                chartData={setChartData("TTI_ALL247_Mean")}
                // titleText={"Travel Time Index (TTI)"}
                // xAxisCategories={["2021 Q3", "2021 Q4"]}
                xAxisCategories={setChartXaxis()}
                xAxisfontSize={12}
                // yAxisTitleText={"TTI"}
                yAxisMin={1}
                enableLegend={false}
                enableExporting={true}
                enabled3d={false}
              />
            </CardContent>
            <CardActions sx={{ borderTopStyle: "inset", bordeWidth: "1px" }}>
              <Grid container spacing={0} className={classesGrid.gridRow1}>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    Current Quarter: {ttiCurrentPreviousQt.current}
                  </Typography>
                </Grid>
                <Grid item xs={6} justifyContent="flex-end" container>
                  <Typography variant="body2">
                    Previous Quarter: {ttiCurrentPreviousQt.previous}
                  </Typography>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <div id={"map"} className={mode}>
            <DeckglMap
              //   regionSelected={regionSelected}
              //   setRegionSelected={setRegionSelected}
              //   statewideData={statewideData}
              //   isLoading={isLoading}
              //   landUseData={landUseData}
              statusGuide={statusGuide}
              deckRef={deckRef}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardHeader
              title="Daily Speed (mph)"
              // subheader="Current Quarter"
              subheader={
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justify: "center",
                  }}
                >
                  <SetCardArrow
                    type={"Speed"}
                    diffValue={speedCurrentPreviousQt.difference}
                  />
                  <SetCardSubtitle
                    type={"Speed"}
                    diffValue={speedCurrentPreviousQt.difference}
                  />
                </Box>
              }
            />
            <CardContent>
              <Bar3dChart
                chartHeight={"335vh"}
                // chartData={[
                //   ttiCurrentPreviousQt.previous,
                //   ttiCurrentPreviousQt.current,
                // ]}
                // chartData={[
                //   speedCurrentPreviousQt.previous,
                //   speedCurrentPreviousQt.current,
                // ]}
                chartData={setChartData("AvgSpeed_ALL247_Mean")}
                // titleText={"Travel Time Index (TTI)"}
                // xAxisCategories={["2021 Q3", "2021 Q4"]}
                xAxisCategories={setChartXaxis()}
                xAxisfontSize={12}
                // yAxisTitleText={"TTI"}
                yAxisMin={40}
                enableLegend={false}
                enableExporting={true}
                enabled3d={false}
              />
            </CardContent>
            <CardActions sx={{ borderTopStyle: "inset", bordeWidth: "1px" }}>
              <Grid container spacing={0} className={classesGrid.gridRow1}>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    Current Quarter: {speedCurrentPreviousQt.current} mph
                  </Typography>
                </Grid>
                <Grid item xs={6} justifyContent="flex-end" container>
                  <Typography variant="body2">
                    Previous Quarter: {speedCurrentPreviousQt.previous} mph
                  </Typography>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={{ mr: 1, mt: 1, mb: 2 }}>
            <CardHeader
              title="Planning Time Index (PTI) - 95th Percentile"
              // subheader="Current Quarter"
              subheader={
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justify: "center",
                  }}
                >
                  <SetCardArrow
                    type={"PTI95"}
                    diffValue={pti95CurrentPreviousQt.difference}
                  />
                  <SetCardSubtitle
                    type={"PTI95"}
                    diffValue={pti95CurrentPreviousQt.difference}
                  />
                </Box>
              }
            />
            <CardContent>
              <Bar3dChart
                chartHeight={"335vh"}
                // chartData={[
                //   pti95CurrentPreviousQt.previous,
                //   pti95CurrentPreviousQt.current,
                // ]}
                chartData={setChartData("PTI95_ALL247_Mean")}
                // titleText={"Travel Time Index (TTI)"}
                // xAxisCategories={["2021 Q3", "2021 Q4"]}
                xAxisCategories={setChartXaxis()}
                xAxisfontSize={12}
                // yAxisTitleText={"TTI"}
                yAxisMin={1}
                enableLegend={false}
                enableExporting={true}
                enabled3d={false}
              />
            </CardContent>
            <CardActions sx={{ borderTopStyle: "inset", bordeWidth: "1px" }}>
              <Grid container spacing={0} className={classesGrid.gridRow1}>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    Current Quarter: {pti95CurrentPreviousQt.current}
                  </Typography>
                </Grid>
                <Grid item xs={6} justifyContent="flex-end" container>
                  <Typography variant="body2">
                    Previous Quarter: {pti95CurrentPreviousQt.previous}
                  </Typography>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <div id={"tableDataPanel"} style={{ marginRight: "2px" }}>
            <TablePanel statusGuide={statusGuide} mobilityData={mobilityData} />
          </div>
        </Grid>
        <Grid item xs={3}>
          <Card sx={{ mt: 1, mb: 2 }}>
            <CardHeader
              title="Planning Time Index (PTI) - 80th Percentile"
              // subheader="Current Quarter"
              subheader={
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justify: "center",
                  }}
                >
                  <SetCardArrow
                    type={"PTI80"}
                    diffValue={pti80CurrentPreviousQt.difference}
                  />
                  <SetCardSubtitle
                    type={"PTI80"}
                    diffValue={pti80CurrentPreviousQt.difference}
                  />
                </Box>
              }
            />
            <CardContent>
              <Bar3dChart
                chartHeight={"335vh"}
                // chartData={[
                //   pti80CurrentPreviousQt.previous,
                //   pti80CurrentPreviousQt.current,
                // ]}
                chartData={setChartData("PTI80_ALL247_Mean")}
                // titleText={"Travel Time Index (TTI)"}
                // xAxisCategories={["2021 Q3", "2021 Q4"]}
                xAxisCategories={setChartXaxis()}
                xAxisfontSize={12}
                // yAxisTitleText={"TTI"}
                yAxisMin={1}
                enableLegend={false}
                enableExporting={true}
                enabled3d={false}
              />
            </CardContent>
            <CardActions sx={{ borderTopStyle: "inset", bordeWidth: "1px" }}>
              <Grid container spacing={0} className={classesGrid.gridRow1}>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    Current Quarter: {pti80CurrentPreviousQt.current}
                  </Typography>
                </Grid>
                <Grid item xs={6} justifyContent="flex-end" container>
                  <Typography variant="body2">
                    Previous Quarter: {pti80CurrentPreviousQt.previous}
                  </Typography>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <ChartsLayout statusGuide={statusGuide} mobilityData={mobilityData} />
        </Grid>
      </Grid>
    </div>
  );
}

export default MainLayout;
