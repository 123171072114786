/**
 * @fileOverview the main UI component
 */

import React, { useState, useRef, useEffect, useMemo } from "react";

import logo from "./logo.svg";
import "./App.css";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeTheme, sidebarStyles, gridStyles } from "./ui/styles";
import "./App.scss";

// import { QueryProvider, QueryContext } from "./context/QueryContext";

// import StatewideLayout from "./layouts/StatewideLayout";
// import SitesLayout from "./layouts/SitesLayout";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuIcon from "@material-ui/icons/Menu";
// import AccountCircle from "@material-ui/icons/AccountCircle";

import { geographies } from "./common/constants";

import Grid from "@material-ui/core/Grid";

import MainLayout from "./layouts/MainLayout";

import Downloads from "./utils/Downloads";

// import fileData from "./TestData/quarterly-truck-mobility.csv";
import fileData from "./TestData/quarterly-truck-mobility-2021-2022-2023Q1.csv";
import Papa from "papaparse";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mode = prefersDarkMode ? "dark" : "light";
  let theme = makeTheme(mode);
  theme = responsiveFontSizes(theme);
  const classes = sidebarStyles();

  // const [statusType, setStatusType] = useState("region");
  const [statusGuide, setStatusGuide] = useState(
    geographies.filter((obj) => obj.category === "state")[0]
  );

  // const handleStatewideClick = (event) => {
  //   setStatusGuide("statewide");
  // };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElRegion, setAnchorElRegion] = React.useState(null);
  const [anchorElDistrict, setAnchorElDistrict] = React.useState(null);
  const [anchorElCounty, setAnchorElCounty] = React.useState(null);

  // const handleChange = (event) => {
  //   setAuth(event.target.checked);
  // };

  // const handleMenu = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleMenu = (type, event) => {
    // console.log("event-check", event.target.id, type);
    // setAnchorElRegion(event.currentTarget);
    // setAnchorElDistrict(event.currentTarget);
    if (type === "region") setAnchorElRegion(event.currentTarget);
    if (type === "district") setAnchorElDistrict(event.currentTarget);
    if (type === "county") setAnchorElCounty(event.currentTarget);
  };

  // const handleCloseMenu = () => {
  //   setAnchorEl(null);
  //   // setStatusGuide("not statewide");
  // };
  const handleCloseMenu = () => {
    setAnchorElRegion(null);
    setAnchorElDistrict(null);
    setAnchorElCounty(null);
    // setStatusGuide("not statewide");
  };

  // const handleClickMenuParkingSite = (item, event) => {
  //   // console.log("item-check", item);
  //   setAnchorEl(null);
  //   setStatusGuide(item.name);
  //   setStatusType("site");
  // };
  const handleClickMenu = (item, event) => {
    // console.log("item-check", item);
    setAnchorElRegion(null);
    setAnchorElDistrict(null);
    setAnchorElCounty(null);
    setStatusGuide(item);
    // setStatusType("region");
  };

  const [mobilityData, setMobilityData] = useState(null);
  useEffect(() => {
    Papa.parse(fileData, {
      download: true,
      header: true,
      complete: function (input) {
        // console.log("input-check", input);

        // const records = input.data;
        // console.log("records-check", records);

        setMobilityData(
          input.data.filter(
            (item) => item.Reporting_Area === statusGuide.reportingArea
          )
        );
        // setMobilityData(input.data);
      },
    });
  }, [statusGuide]);

  const printRef = React.useRef();
  const deckRef = React.useRef();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Grid container justify="center">
              {/* <Grid item xs={1}>
                <Box
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <img
                    className="logo"
                    src="Transparent Background Color3lineLOGO_TxDOT_WHITE_300dpi.png"
                    alt="Maryland DOT Logo"
                    title="Maryland DOT Logo"
                  />
                </Box>
              </Grid> */}
              {/* <Grid item xs={1}>
                <Box
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                >
                 
                  <img
                    // className="logo"
                    src={"TTI-Color-reverse.png"}
                    alt="TTI Logo"
                    title="TTI Logo"
                  />
                </Box>
              </Grid> */}

              <Grid
                item
                // xs={8}
                xs={10}
                align="left"
                justify="center"
                sx={{ padding: 0, margin: 0 }}
              >
                <Box
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <Typography variant="h4" noWrap>
                    Texas Quarterly Truck Mobility Reports
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2} align="right">
                <Box
                  height="100%"
                  display="flex"
                  justifyContent="flex-end"
                  flexDirection="row"
                >
                  <IconButton
                    size="medium"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={(event) =>
                      setStatusGuide(
                        geographies.filter(
                          (item) => item.category === "state"
                        )[0]
                      )
                    }
                    color="inherit"
                    id="region"
                  >
                    <Box sx={{ m: 0.5 }}>
                      <Typography variant="h6" noWrap>
                        State
                      </Typography>
                    </Box>
                  </IconButton>
                  <IconButton
                    size="medium"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={(event) => handleMenu("region", event)}
                    color="inherit"
                    id="region"
                  >
                    <Box sx={{ m: 0.5 }}>
                      <Typography variant="h6" noWrap>
                        Region
                      </Typography>
                    </Box>
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElRegion}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElRegion)}
                    // onClose={handleCloseParkingSitesMenu}
                    onClose={handleCloseMenu}
                  >
                    {geographies
                      .filter((obj) => obj.category == "region")
                      .map((item) => (
                        <MenuItem
                          key={"region-" + item.reportingArea}
                          onClick={(event) => handleClickMenu(item, event)}
                          // onClick={(event) => handleClickMenu(item, event)}
                        >
                          <Typography textAlign="center">
                            {item.label}
                          </Typography>
                        </MenuItem>
                      ))}
                  </Menu>
                  <IconButton
                    size="medium"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    // onClick={handleMenu}
                    onClick={(event) => handleMenu("district", event)}
                    color="inherit"
                  >
                    <Box sx={{ m: 0.5 }}>
                      <Typography variant="h6" noWrap>
                        District
                      </Typography>
                    </Box>
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElDistrict}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElDistrict)}
                    // onClose={handleCloseParkingSitesMenu}
                    onClose={handleCloseMenu}
                  >
                    {geographies
                      .filter((obj) => obj.category == "district")
                      .map((item) => (
                        <MenuItem
                          key={"district-" + item.reportingArea}
                          onClick={(event) => handleClickMenu(item, event)}
                          // onClick={(event) => handleClickMenu(item, event)}
                        >
                          <Typography textAlign="center">
                            {item.label}
                          </Typography>
                        </MenuItem>
                      ))}
                  </Menu>
                  <IconButton
                    size="medium"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={(event) => handleMenu("county", event)}
                    color="inherit"
                  >
                    <Box sx={{ m: 0.5 }}>
                      <Typography variant="h6" noWrap>
                        County
                      </Typography>
                    </Box>
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElCounty}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElCounty)}
                    // onClose={handleCloseParkingSitesMenu}
                    onClose={handleCloseMenu}
                  >
                    {geographies
                      .filter((obj) => obj.category == "county")
                      .map((item) => (
                        <MenuItem
                          key={"county-" + item.reportingArea}
                          onClick={(event) => handleClickMenu(item, event)}
                          // onClick={(event) => handleClickMenu(item, event)}
                        >
                          <Typography textAlign="center">
                            {item.label}
                          </Typography>
                        </MenuItem>
                      ))}
                  </Menu>
                  <Downloads
                    deckRef={deckRef}
                    printRef={printRef}
                    mobilityData={mobilityData}
                  />
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <MainLayout
            statusGuide={statusGuide}
            mobilityData={mobilityData}
            printRef={printRef}
            deckRef={deckRef}
          />
          {/* <QueryProvider>
            <div className={classes.toolbar} />
          </QueryProvider> */}
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
